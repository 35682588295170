import React, { Component } from 'react';
import axios from 'axios';
import VirtualBackgroundCreateOrUpdate from './components/VirtualBackgroundCreateOrUpdate';

export default class VirtualBackgroundPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            models: [],
            model: null,
        };
        this.getAllVirtualBackground = this.getAllVirtualBackground.bind(this);
        this.createOrUpdateVirtualBackground = this.createOrUpdateVirtualBackground.bind(this);
    }

    componentWillMount() {
        this.getAllVirtualBackground();
    }

    getAllVirtualBackground() {
        let url = global.config.apiDomain + "/rest/virtualBackground/getAllVirtualBackground"
        axios.post(url)
            .then(res => {
                this.setState({
                    models: res.data.models
                })
            })
    }
    createOrUpdateVirtualBackground(event) {
        let index = event.target.name;
        let model = null;
        if (index === "-1") {
            model = {
                id: ''
            }
        } else {
            model = this.state.models[index];
        }
        this.setState({
            model: model
        })

    }

    render() {
        const { models, model } = this.state;


        return (
            <>
                <VirtualBackgroundCreateOrUpdate
                    model={model}
                    resetList={this.getAllVirtualBackground}
                    isCreate = {!(model && !!model.id)}
                ></VirtualBackgroundCreateOrUpdate>
                <div id="content">
                    <div className="container-fluid">
                        <h1 className="text-center">Quản lý Virtual Background</h1>
                        <button
                            className="btn btn-info mb-1"
                            data-toggle="modal"
                            data-target="#modalCreate"
                            name="-1"
                            onClick={this.createOrUpdateVirtualBackground}
                        >Tạo mới quà
                        </button>
                        <table className="table table-hover">
                            <thead>
                                <tr className="text-center">
                                    <th className="align-middle" scope="col">STT</th>
                                    <th className="align-middle" scope="col">ID</th>
                                    <th className="align-middle" scope="col">Tên background</th>
                                    <th className="align-middle" scope="col">Ngày tạo</th>
                                    <th className="align-middle" scope="col">Thumbnail</th>
                                    <th className="align-middle" scope="col">Giới hạn level</th>
                                    <th className="align-middle" scope="col">Giới hạn VIP level</th>
                                    <th className="align-middle" scope="col">Trạng thái</th>
                                    <th className="align-middle" scope="col">Tuỳ chỉnh</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    models && models.map((item, index) => {
                                        return (
                                            <tr>
                                                <td className="align-middle text-center" scope="col">{index + 1}</td>
                                                <td className="align-middle text-center" scope="col">{item.id}</td>
                                                <td className="align-middle text-center" scope="col">{item.name}</td>
                                                <td className="align-middle text-center" scope="col">{new Date(item.addTime).toLocaleString()}</td>
                                                <td className="align-middle text-center" scope="col"> <img src={item.url} style={{ maxWidth: '100px', maxHeight: '300px' }} ></img></td>
                                                <td className="align-middle text-center" scope="col">{item.levelRequirement}</td>
                                                <td className="align-middle text-center" scope="col">{item.levelVipRequirement}</td>
                                                <td className="align-middle text-center" scope="col">
                                                {item.isActive ? (
                                                        <button className='btn btn-primary'>Đang sử dụng</button>
                                                    ) : (
                                                        <button className='btn btn-danger'>Đang ẩn</button>
                                                    )}
                                                </td>
                                                <td className="align-middle text-center" scope="col">

                                                    <button
                                                        className="btn btn-success"
                                                        onClick={this.createOrUpdateVirtualBackground}
                                                        name={index}
                                                        data-toggle="modal"
                                                        data-target="#modalCreate"
                                                    >
                                                        Sửa
                                                    </button>

                                                </td>
                                            </tr>
                                        )
                                    })

                                }

                            </tbody>
                        </table>
                    </div>
                </div>

            </>
        );
    }
}