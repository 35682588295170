import React, { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import DateTimePicker from 'react-datetime-picker';
import ModalDialogNotify from './components/ModalDialogNotify';


export default class NotifyPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            language: "ALL",
            title: "",
            message: "",
            deeplink: "",
            timePush: "",
            timePick: "",
            fromLevel: null,
            toLevel: null,
            pushType: "ALL",
            loading: false
        }

        this.pushNotification = this.pushNotification.bind(this);
        this.getGifts = this.getGifts.bind(this);
    }

    pushNotification() {

        this.setState({
            loading: true
        });

        var json = {
            message: this.state.message,
            title: this.state.title,
            language: this.state.language,
            deeplink: this.state.deeplink,
            timePush: this.state.timePush,
            fromLevel: this.state.fromLevel,
            toLevel: this.state.toLevel,
            pushType: this.state.pushType,
        }

        console.log(JSON.stringify(json))

        axios.post(global.config.apiDomain + '/rest/notification/pushNotify', JSON.stringify(json), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.data.status == "OK") {
                this.setState({
                    message: "",
                    language: "ALL",
                    title: "",
                    deeplink: "",
                    timePush: "",
                    timePick: "",
                    fromLevel: null,
                    toLevel: null,
                    pushType: "ALL",
                })
                toast.success(res.data.message);
                console.log(res.data.message);
            } else {
                toast.error(res.data.message);
            }
            this.setState({
                loading: false
            });
        }, err => {
            toast.error("ERR");
        });
    }

    getGifts(deeplink) {
        this.setState({
            deeplink: deeplink
        });
    }

    handleChange(event) {
        let target = event.target;
        let name = target.name;
        let value = target.value;

        this.setState({
            [name]: value
        });
    }

    render() {
        return (
            <>
                <ModalDialogNotify resetList={this.getGifts}></ModalDialogNotify>
                <div id="content">
                    <div className="container p-5">
                        <h2 className="m-3 text-center">Thông báo toàn ứng dụng</h2>

                    <div className='iconNotification'>
                        <p alt="Happy Facebook Emoji" title="Happy smiley face" data-id="0">🙂</p>
                        <p alt="Laughing Closed Eyes" title="Toothy grin" data-id="2" class="active">😄<span class="remove"></span></p>
                        <p alt="Grinning emoji with squinted eyes" title="Grinning and squinting eyes" data-id="3" class="active">😆<span class="remove"></span></p>
                        <p alt="Smiling with sweat Emoji" title="Grinning with sweat drop" data-id="4" class="active">😅<span class="remove"></span></p>
                        <p alt="LOL Emoji" title="Laughing with tears of joy" data-id="5">😂</p>
                        <p alt="ROFL Emoji" title="Rolling on the floor laughing" data-id="6" class="active">🤣<span class="remove"></span></p>
                        <p alt="Smiling and blushing emoji" title="Smug smile and blushing" data-id="7" >😊</p>
                        <p alt="Heart-eyes Emoji" title="Smiley with heart-eyes" data-id="12">😍</p>
                        <p alt="Emoji blowing a kiss" title="Throwing a kiss" data-id="13" >😘</p>
                        <p alt="Kissing emoji" title="Kissing smiley with closed eyes" data-id="15" >😙</p>
                        <p alt="Kiss emoji blushing" title="Kissing smiley face blushing" data-id="16">😚</p>
                        <p alt="Hug Facebook Emoji" title="Hugging smiley face" data-id="17" >🤗</p>
                        <p alt="Sarcasm Emoji" title="Upside-down smiley face" data-id="19" >🙃</p>
                        <p alt="Angel emoji with halo" title="Angel smiley" data-id="20" >😇</p>
                        <p alt="Tongue out emoji" title="Sticking tongue out" data-id="22" >😛</p>
                        <p alt="Facebook emoji with tongue stuck out" title="Tongue-out and squinting" data-id="23" >😝</p>
                        <p alt="Cheeky emoji" title="Tongue-out and winking" data-id="24" >😜</p>
                        <p alt="Delicious emoji" title="Smiley licking its mouth" data-id="25" >😋</p>
                        <p alt="Nerdy emoji" title="Smiley face with eyeglasses" data-id="27" >🤓</p>
                        <p alt="Cool emoji with shades" title="Smiley face with sunglasses" data-id="28" >😎</p>
                        <p alt="Money-eyes emoji" title="Money smiley" data-id="29">🤑</p>
                        <p alt="Sad emoji cold sweating" title="Sad face with sweat drop" data-id="36" >😓</p>
                        <p alt="Frown emoji sweating" title="Frowny face with sweat drop" data-id="37" >😢</p>
                        <p alt="Sobbing emoji" title="Sobbing" data-id="39" >😭</p>
                        <p alt="Thinking emoji" title="Thinking" data-id="45" >🤔</p>
                        <p alt="Eye-roll emoji" title="Rolling eyes" data-id="46" >🙄</p>
                        <p alt="Mad emoji with steam" title="Steaming mad" data-id="47" >😤</p>
                        <p alt="Grumpy emoji" title="Grumpy" data-id="49" >😡</p>
                        <p alt="Zipper mouth emoji" title="Lips sealed" data-id="51" >🤐</p>
                        <p alt="Astonished emoji with open mouth" title="Shocked" data-id="55" >😲</p>
                        <p alt="Screaming emoji in fear" title="Screaming in fear" data-id="59" >😱</p>
                        <p alt="Sleepy Facebook Emoticon" title="Sleepy emoji with snot bubble" data-id="60" >😪</p>
                        <p alt="Sleeping emoji zzz" title="Sleeping and snoring" data-id="61" >😴</p>
                        <p alt="Sneezing emoji" title="Sneezing" data-id="64" >🤧</p>
                        <p alt="Sick Facebook Emoticon" title="Sick emoji with thermometer" data-id="65" >🤒</p>
                        <p alt="Doctor emoji" title="Smiley with medical mask" data-id="66" >😷</p>
                        <p alt="Cowboy emoji" title="Smiley with cowboy hat" data-id="70" >🤠</p>
                        <p alt="Clown emoji" title="Smiling clown face" data-id="71" >🤡</p>
                        <p alt="Ghost Facebook Emoticon" title="Ghost emoji" data-id="75" >👻</p>
                        <p alt="Pumpkin emoji - Halloween emoticon" title="Jack-o'lantern" data-id="81">🎃</p>
                        <p alt="Yellow heart emoji" title="Yellow heart" data-id="2">💛</p>
                        <p alt="Green Facebook Heart" title="Green heart" data-id="3">💚</p>
                        <p alt="Blue heart emoji" title="Blue heart" data-id="4">💙<span class="remove"></span></p>
                        <p alt="Purple heart for Facebook" title="Purple heart" data-id="5">💜</p>
                        <p alt="Black heart Facebook emoji" title="Black heart" data-id="6" >🖤</p>
                        <p alt="Sparkling heart emoji" title="Sparkly heart" data-id="7" >💖</p>
                        <p alt="Gift heart" title="Heart with ribbon" data-id="8" >💝</p>
                        <p alt="Broken heart Facebook emoji" title="Broken heart" data-id="9" >💔</p>
                        <p alt="Heart above dot" title="Exclamation heart" data-id="10" >❣️</p>
                        <p alt="Two hearts emoticons for Facebook" title="Two hearts" data-id="11" >💕</p>
                        <p alt="Spinning hearts emoji" title="Revolving hearts" data-id="12" >💞</p>
                        <p alt="Heartbeat emoji" title="Beating heart" data-id="13">💓</p>
                        <p alt="Triple emoji hearts" title="Growing heart" data-id="14" >💗</p>
                        <p alt="Heart arrow emoji" title="Heart with arrow" data-id="15" >💘</p>
                        <p alt="Heart decoration" title="Heart badge" data-id="16" >💟</p>
                        <p alt="Heart envelope" title="Love letter" data-id="17" >💌</p>
                        <p alt="Kiss mark emoji for Facebook" title="Kiss mark" data-id="18" >💋</p>
                        <p alt="Lips emoji" title="Mouth emoji" data-id="19" >👄</p>
                        <p alt="Makeup emoji lipstick" title="Lipstick" data-id="20" >💄</p>
                        <p alt="Engagement ring emoji" title="Diamond ring" data-id="21" >💍</p>
                        <p alt="Gift emoji" title="Gift" data-id="23">🎁</p>
                        <p alt="School bag emoji" title="Backpack" data-id="49">🎒</p>
                        <p alt="Shopping emoji" title="Shopping bags" data-id="50">🛍️</p>
                        <p alt="Cinema emoji movies" title="Cinema" data-id="53" >️🎦</p>
                        <p alt="Artist palette emoji" title="Painting" data-id="54" >️🎨</p>
                        <p alt="Juggler emoji" title="Juggling" data-id="55" >️🤹</p>
                        <p alt="Confetti emoji" title="Confetti" data-id="56" >️🎊</p>
                        <p alt="Party emoji" title="Party popper" data-id="57" >️🎉</p>
                        <p alt="Balloon emoji" title="Balloon" data-id="58" >️🎈</p>
                        <p alt="Headphones emoji" title="Headphone" data-id="59">️🎧</p>
                        <p alt="Saxophone emoji" title="Saxophone" data-id="60" >️🎷</p>
                        <p alt="Trumpet emoji" title="Trumpet" data-id="61" >️🎺</p>
                        <p alt="Guitar emoji" title="Guitar" data-id="62" >️🎸</p>
                        <p alt="Violin" title="Violin" data-id="63">️🎻</p>
                        <p alt="Drum" title="Drum" data-id="64">️🥁</p>
                        <p alt="Piano keyboard emoji" title="Piano" data-id="65" >️🎹</p>
                        <p alt="Microphone" title="Microphone" data-id="66" >️🎤</p>
                        <p alt="Music note emoji" title="Music note" data-id="67">️🎵</p>
                        <p alt="Music notes" title="Music notes" data-id="68" >️🎶</p>
                        <p alt="Music symbol" title="Sheet music" data-id="69" >️🎼</p>
                        <p alt="Military Medal" title="Medal" data-id="94" >️🎖️</p>
                        <p alt="Sports Medal" title="Sports medal" data-id="95" >️🏅</p>
                        <p alt="Gold medal emoji" title="Gold medal" data-id="96" >️🥇</p>
                        <p alt="Second place" title="Silver medal" data-id="97" >️🥈</p>
                        <p alt="Last place" title="Bronze medal" data-id="98" >️🥉</p>
                        <p alt="Trophy emoji" title="Trophy" data-id="99" >️🏆</p>
                        <p alt="Blossom emoji" title="Blossoming flower" data-id="0">🌼</p>
                        <p alt="Pink flower emoji" title="Cherry Blossom" data-id="1" >🌸</p>
                        <p alt="Hibiscus emoji flower" title="Hibiscus" data-id="2" >🌺</p>
                        <p alt="Rose emoji" title="Rosette" data-id="3" >🏵️</p>
                        <p alt="Sunflower emoji" title="Sunflower" data-id="4" >🌻</p>
                        <p alt="Tulip emoji" title="Tulip" data-id="5" >🌷</p>
                        <p alt="Red rose emoji" title="Rose" data-id="6" >🌹</p>
                        <p alt="Drooping flower" title="Wilted flower" data-id="7" >🥀</p>
                        <p alt="Bouquet emoji" title="Bouquet of flowers" data-id="8" >💐</p>
                        <p alt="Wheat emoji" title="Crop" data-id="9" >🌾</p>
                        <p alt="Tanabata emoji" title="Wish tree" data-id="10" >🎋</p>
                        <p alt="Shamrock emoji" title="Three-leaf clover" data-id="11" >☘</p>
                        <p alt="Four leaves clover emoji" title="Four-leaf clover" data-id="12" >🍀</p>
                        <p alt="Spring emoji" title="Green leaves" data-id="13" >🍃</p>
                        <p alt="Autumn emoji" title="Fallen leaf" data-id="14" >🍂</p>
                        <p alt="Maple leaf emoji" title="Maple leaf" data-id="15" >🍁</p>
                        <p alt="Sprout emoji" title="Seedling" data-id="16" >🌱</p>
                        <p alt="Herb emoji" title="Plant" data-id="17" >🌿</p>
                        <p alt="Bamboo emoji" title="Bamboo decoration" data-id="18" >🎍</p>
                        <p alt="Cactus emoji" title="Cactus" data-id="19" >🌵</p>
                        <p alt="Palm emoji" title="Palm tree" data-id="20" >🌴</p>
                        <p alt="Green tree emoji" title="Green tree" data-id="21" >🌳</p>
                        <p alt="Pine emoji" title="Pine tree" data-id="22" >🌳</p>
                        <p alt="Christmas tree emoji" title="Christmas tree" data-id="23" >🎄</p>
                        <p alt="Shroom emoji" title="Mushroom" data-id="24" >🍄</p>
                        <p alt="Earth emoji" title="Earth Globe Americas" data-id="25" >🌎</p>
                        <p alt="Planet Earth" title="Earth Globe Europe-Africa" data-id="26" >🌍</p>
                        <p alt="World emoji" title="Earth Globe Asia-Australia" data-id="27" >🌏</p>
                        <p alt="Smiling Moon Emoji" title="Last quarter moon smiling" data-id="28" >🌜</p>
                        <p alt="Moon smiley" title="First quarter moon" data-id="29" >🌛</p>
                        <p alt="Crescent Moon" title="Crescent Moon" data-id="40" >🌙</p>
                        <p alt="Spinning stars emoji" title="Spinning stars" data-id="41" >💫</p>
                        <p alt="Star emoji" title="Star" data-id="42" >⭐</p>
                        <p alt="Shining star emoji" title="Glowing star" data-id="43" >🌟</p>
                        <p alt="Sparkles emoji" title="Sparkling stars" data-id="44" >✨</p>
                        <p alt="Thunder emoji" title="Thunderbolt" data-id="45" >⚡</p>
                        <p alt="Fire flame emoji" title="Fire" data-id="46" >🔥</p>
                        <p alt="Exploding emoji" title="Explosion" data-id="47" >💥</p>
                        <p alt="Comet emoji" title="Comet" data-id="48" >☄️</p>
                        <p alt="Sun smiley" title="Smiling sun" data-id="49" >🌞</p>
                        <p alt="Sun emoji" title="Sun" data-id="50" >☀️</p>
                        <p alt="Partly cloudy emoji" title="Sun behind small cloud" data-id="51" >🌤️</p>
                        <p alt="Cloudy emoji" title="Sun behind cloud" data-id="52" >⛅</p>
                        <p alt="Very cloudy" title="Sun behind large cloud" data-id="53" >🌥️</p>
                        <p alt="Sun, clouds and rain emoji" title="Sun behind rain cloud" data-id="54" >🌦️</p>
                        <p alt="Cloud emoji" title="Cloud" data-id="55" >☁️</p>
                        <p alt="Raining clouds emoji" title="Cloud With Rain" data-id="56" >🌧️</p>
                        <p alt="Storm emoji" title="Lightning and rain" data-id="57" >⛈️</p>
                        <p alt="Cloud emoji with lightning" title="Cloud with lightning" data-id="58" >🌩️</p>
                        <p alt="Snowing emoji" title="Snowing" data-id="59" >🌨️</p>
                        <p alt="Rainbow emoji" title="Rainbow" data-id="60" >🌈</p>
                        <p alt="Water drop emoji" title="Drop" data-id="61" >💧</p>
                        <p alt="Raining droplets emoji" title="Droplets" data-id="62" >💦</p>
                        <p alt="Umbrella emoji" title="Umbrella" data-id="63" >☂️</p>
                        <p alt="Umbrella rainy emoji" title="Umbrella with rain drops" data-id="64" >☔</p>
                        <p alt="Smiling kitten emoji" title="Happy cat face" data-id="0" >😺</p>
                        <p alt="Grinning cat emoji" title="Grinning cat" data-id="1" >😸</p>
                        <p alt="Cat emoji laughing with tears" title="Cat with happy tears" data-id="2" >😹</p>
                        <p alt="Cat emoji in love" title="Heart-eyes cat" data-id="3" >😻</p>
                        <p alt="Wry smile emoji" title="Smirking cat" data-id="4" >😼</p>
                        <p alt="Kissing emoji cat" title="Kissing cat" data-id="5" >😽</p>
                        <p alt="Scared cat emoticon" title="Scrunched eyes cat" data-id="6" >🙀</p>
                        <p alt="Sad cat emoji crying" title="Crying cat" data-id="7">😿</p>
                        <p alt="Grumpy emoji for Facebook" title="Grumpy cat" data-id="8" >😾</p>
                        <p alt="Kitty emoji for Facebook" title="Kitten emoji" data-id="9" >🐱</p>
                        <p alt="Puppy emoji" title="Puppy face" data-id="10" >🐶</p>
                        <p alt="Bunny emoji" title="Bunny" data-id="11" >🐰</p>
                        <p alt="Mouse emoji" title="Mouse" data-id="12" >🐭</p>
                        <p alt="Hamster emoji" title="Hamster" data-id="13" >🐹</p>
                        <p alt="Fox emoji" title="Fox" data-id="14" >🦊</p>
                        <p alt="Teddy bear emoji" title="Bear" data-id="15" >🐻</p>
                        <p alt="Panda emoji" title="Panda" data-id="16" >🐼</p>
                        <p alt="Koala emoji" title="Koala" data-id="17">🐨</p>
                        <p alt="Tiger emoji" title="Tiger face" data-id="18" >🐯</p>
                        <p alt="Lion emoji" title="Lion face" data-id="19" >🦁</p>
                        <p alt="Cow emoji" title="Cow face" data-id="20">🐮</p>
                        <p alt="Boar emoji" title="Wild boar" data-id="21" >🐗</p>
                        <p alt="Pig emoji" title="Piggy" data-id="22">🐷</p>
                        <p alt="Pig snout emoji" title="Pig nose" data-id="23">🐽</p>
                        <p alt="Frog emoji" title="Frog" data-id="24">🐸</p>
                        <p alt="Monkey emoji" title="Monkey face" data-id="25" >🐵</p>
                        <p alt="See-no monkey" title="Monkey covering eyes" data-id="26" >🙈</p>
                        <p alt="Hear-no monkey" title="Monkey covering ears" data-id="27" >🙉</p>
                        <p alt="Speak-no monkey" title="Monkey covering mouth" data-id="28" >🙊</p>
                        <p alt="Harambe emoji" title="Gorilla" data-id="29" >🦍</p>
                        <p alt="Wolf emoji" title="Wolf" data-id="30" >🐺</p>
                        <p alt="Lamb emoji sheep" title="Sheep" data-id="31">🐑</p>
                        <p alt="Goat emoji" title="Goat" data-id="32" >🐐</p>
                        <p alt="Ram emoji" title="Ram" data-id="33" >🐏</p>
                        <p alt="Horse head emoji" title="Horse face" data-id="34" >🐴</p>
                        <p alt="Unicorn emoji" title="Unicorn" data-id="35" >🦄</p>
                        <p alt="Deer emoji" title="Deer" data-id="36">🦌</p>
                        <p alt="Rhino emoji" title="Rhino" data-id="37" >🦏</p>
                        <p alt="Eagle emoji" title="Eagle" data-id="38" >🦅</p>
                        <p alt="Chick emoji" title="Chick face" data-id="39" >🐤</p>
                        <p alt="Hatching chick emoticon" title="Hatching chick" data-id="40" >🐣</p>
                        <p alt="Baby chick emoji" title="Baby chick" data-id="41" >🐥</p>
                        <p alt="Hen emoji" title="Chicken" data-id="42" >🐔</p>
                        <p alt="Cock emoji" title="Rooster" data-id="43" >🐓</p>
                        <p alt="Thanksgiving emoji" title="Turkey" data-id="44" >🦃</p>
                        <p alt="Bird emoji" title="Bird" data-id="45" >🐦</p>
                        <p alt="Duck emoji" title="Duck" data-id="46" >🦆</p>
                        <p alt="Bat emoji" title="Bat" data-id="47" >🦇</p>
                        <p alt="Owl emoji" title="Owl" data-id="48" >🦉</p>
                        <p alt="Peace dove emoji" title="Dove" data-id="49" >🕊️</p>
                        <p alt="Penguin emoji - Facebook emoticon" title="Penguin" data-id="50" >🐧</p>
                        <p alt="Dog emoji" title="Puppy" data-id="51">🐕</p>
                        <p alt="Poodle emoji" title="Poodle" data-id="52" >🐩</p>
                        <p alt="Cat emoji" title="Cat" data-id="53" >🐈</p>
                        <p alt="Rabbit emoji" title="Rabbit" data-id="54" >🐇</p>
                        <p alt="Mouse emoji" title="Mouse" data-id="55">🐁</p>
                        <p alt="Rat emoji" title="Rat" data-id="56" >🐀</p>
                        <p alt="Squirrel emoji" title="Chipmunk" data-id="57" >🐿</p>
                        <p alt="Monkey emoticon" title="Monkey" data-id="58">🐒</p>
                        <p alt="Piggy emoji" title="Piggy" data-id="59" >🐖</p>
                        <p alt="Leopard emoji" title="Leopard" data-id="60" >🐆</p>
                        <p alt="Tiger emoticon" title="Tiger" data-id="61">🐅</p>
                        <p alt="Water Buffalo Emoji" title="Buffalo" data-id="62" >🐃</p>
                        <p alt="Ox emoji" title="Ox" data-id="63" >🐂</p>
                        <p alt="Cow emoticon" title="Cow" data-id="64" >🐄</p>
                        <p alt="Horse emoticon" title="Galloping horse" data-id="65">🐎</p>
                        <p alt="Camel emoji" title="Camel" data-id="66" >🐪</p>
                        <p alt="Double hump camel" title="Two-hump camel" data-id="67" >🐫</p>
                        <p alt="Elephant emoji" title="Elephant" data-id="68" >🐘</p>
                        <p alt="Crocodile Emoji" title="Crocodile" data-id="69">🐊</p>
                        <p alt="Turtle emoji" title="Turtle" data-id="70" >🐢</p>
                        <p alt="Tropical emoji" title="Tropical fish" data-id="71" >🐠</p>
                        <p alt="Fish emoji" title="Fish" data-id="72" >🐟</p>
                        <p alt="Blowfish emoticon" title="Blowfish" data-id="73" >🐡</p>
                        <p alt="Dolphin emoji" title="Dolphin" data-id="74">🐬</p>
                        <p alt="Shark emoji" title="Shark" data-id="75" >🦈</p>
                        <p alt="Spouting whale" title="Spouting whale" data-id="76" >🐳</p>
                        <p alt="Whale emoji" title="Whale" data-id="77" >🐋</p>
                        <p alt="Squid emoji" title="Squid" data-id="78">🦑</p>
                        <p alt="Octopus emoji" title="Octopus" data-id="79" >🐙</p>
                        <p alt="Shrimp emoji" title="Shrimp" data-id="80" >🦐</p>
                        <p alt="Shell emoji" title="Seashell" data-id="81" >🐚</p>
                        <p alt="Crab emoji" title="Crab" data-id="82">🦀</p>
                        <p alt="Scorpion emoji" title="Scorpion" data-id="83" >🦂</p>
                        <p alt="Lizard emoji" title="Lizard" data-id="84" >🦎</p>
                        <p alt="Snake emoji" title="Snake" data-id="85" >🐍</p>
                        <p alt="Worm emoji" title="Caterpillar" data-id="86" >🐛</p>
                        <p alt="Ant emoji" title="Ant" data-id="87" >🐜</p>
                        <p alt="Spider emoji" title="Spider" data-id="88" >🕷️</p>
                        <p alt="Spider web emoji" title="Spider web" data-id="89" >🕸️</p>
                        <p alt="Lady beetle emoji" title="Ladybug" data-id="90" >🐞</p>
                        <p alt="Butterfly emoji" title="Butterfly" data-id="91" >🦋</p>
                        <p alt="Honey bee emoji" title="Bee" data-id="92" >🐝</p>
                        <p alt="Snail emoji" title="Snail" data-id="93">🐌</p>
                        <p alt="Dragon face" title="Dragon head" data-id="94" >🐲</p>
                        <p alt="Dragon emoji" title="Dragon" data-id="95" >🐉</p>
                        <p alt="Paws emoji" title="Paw prints" data-id="96" >🐾</p>
                        <p alt="Thumbs up emoji" title="Thumbs up" data-id="0" >👍</p>
                        <p alt="Thumbs down emoji" title="Thumbs down" data-id="1" >👎</p>
                        <p alt="Peace emoji" title="Peace fingers" data-id="2" >✌️</p>
                        <p alt="Fingers crossed emoji" title="Crossed fingers" data-id="3" >🤞</p>
                        <p alt="OK emoji" title="Perfect" data-id="4" >👌</p>
                        <p alt="Call me emoji" title="Call me" data-id="5" >🤙</p>
                        <p alt="Rock on hand gesture" title="Rock on" data-id="6" >🤘</p>
                        <p alt="Middle finger emoji -  Flipping the bird" title="Rude finger" data-id="7" >🖕</p>
                        <p alt="Index finger emoji" title="Index finger" data-id="8" >☝️</p>
                        <p alt="Manicure emoji" title="Nail polish" data-id="9" >💅</p>
                        <p alt="Pointing right emoji" title="Pointing right" data-id="10" >👉</p>
                        <p alt="Pointing left emoji" title="Pointing left" data-id="11" >👈</p>
                        <p alt="Pointing down finger emoji" title="Pointing down" data-id="12" >👇</p>
                        <p alt="Up pointing finger emoji" title="Pointing up" data-id="13" >👆</p>
                        <p alt="Punch emoji" title="Fist bump" data-id="14" >👊</p>
                        <p alt="Raising fist" title="Raised fist" data-id="15" >✊</p>
                        <p alt="Fist bump emoji" title="Right-facing fist" data-id="16" >🤜</p>
                        <p alt="Fist emoticon" title="Left-facing fist" data-id="17" >🤛</p>
                        <p alt="Strong muscles emoji" title="Flexed biceps" data-id="18" >💪</p>
                        <p alt="Writing emoji" title="Writing hand" data-id="19" >✍️</p>
                        <p alt="Praying hands emoji" title="Praying hands" data-id="20" >🙏</p>
                        <p alt="Selfie emoji" title="Selfie hand" data-id="21" >🤳</p>
                        <p alt="Clapping hands emoji" title="Applause" data-id="22" >👏</p>
                        <p alt="Handshake emoji" title="Handshake" data-id="23" >🤝</p>
                        <p alt="Raising hands emoji" title="Celebrating" data-id="24" >🙌</p>
                        <p alt="Open hands emoticon" title="Open hands" data-id="25" >👐</p>
                        <p alt="Raised hand emoji" title="High five" data-id="26" >✋</p>
                        <p alt="Hi Emoji" title="Open hand" data-id="27" >🖐️</p>
                        <p alt="Waving emoji" title="Waving hand" data-id="28" >👋</p>
                        <p alt="Spock emoji" title="Vulcan salute" data-id="29" >🖖</p>
                        <p alt="Silhouette emoji" title="Silhouette" data-id="37" >👤</p>
                        <p alt="Silhouette pair emoji" title="Silhouette pair" data-id="38">👥</p>
                        <p alt="Two emojis in love" title="Couple holding hands" data-id="87" >👫</p>
                        <p alt="Two women emoji" title="Female couple holding hands" data-id="88" >👭</p>
                        <p alt="Two men emoji" title="Male couple holding hands" data-id="89" >👬</p>
                        <p alt="Family emoji" title="Parents with son" data-id="105" >👪</p>
                    </div>

                        <div className="input-group mb-3">
                            <input value={this.state.title} onChange={(event) => {
                                this.setState({
                                    title: event.target.value
                                })
                            }}
                                type="text" className="form-control" placeholder="Nhập tiêu đề" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                        </div>

                        <div className="input-group mb-3">
                            <input value={this.state.message} onChange={(event) => {
                                this.setState({
                                    message: event.target.value
                                })
                            }}
                                type="text" className="form-control" placeholder="Nhập nội dung" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                        </div>

                        <div className="input-group mb-3">
                            <input value={this.state.deeplink} onChange={(event) => {
                                this.setState({
                                    deeplink: event.target.value
                                })
                                
                            }}
                            data-toggle="modal"
                            data-target="#modalDialogNotify"
                            type="text" className="form-control" placeholder="Nhập url" aria-label="Recipient's username" aria-describedby="basic-addon2" />

                        </div>
                        <div className="input-group mb-3">
                            {/* <div className="col-md-4 col-sm-12"> */}
                                <select
                                    name="sender"
                                    value={this.state.pushType}
                                    className="form-control"
                                    onChange={(event) => {
                                        this.setState({
                                            pushType: event.target.value
                                        });
                                    }}>
                                    <option value="ALL">ALL</option>
                                    <option value="NEWBIE">Newbie</option>
                                    <option value="NOT_NEWBIE">User không phải Newbie</option>
                                    <option value="LEVEL_RANGE">Push theo level</option>
                                    <option value="VIP">User Vip</option>
                                    <option value="NOT_VIP">User không phải Vip</option>
                                </select>
                            {/* </div> */}
                            {this.state.pushType === 'LEVEL_RANGE' && (
                                <>
                                    {/* Column for the 'From Level' input */}
                                    <div className="col-md-4 col-sm-6" style={{marginLeft :'15px'}}>
                                        <input
                                            value={this.state.fromLevel}
                                            onChange={(event) => {
                                                this.setState({
                                                    fromLevel: event.target.value
                                                });
                                            }}
                                            type="text"
                                            className="form-control"
                                            placeholder="From Level"
                                            aria-label="From Level"
                                            aria-describedby="basic-addon2"
                                        />
                                    </div>

                                    {/* Column for the 'To Level' input */}
                                    <div className="col-md-4 col-sm-6" style={{marginRight:0 ,paddingRight:0}}>
                                        <input
                                            value={this.state.toLevel}
                                            onChange={(event) => {
                                                this.setState({
                                                    toLevel: event.target.value
                                                });
                                            }}
                                            type="text"
                                            className="form-control"
                                            placeholder="To Level"
                                            aria-label="To Level"
                                            aria-describedby="basic-addon2"
                                        />
                                    </div>
                                </>
                            )}
                        </div>

                        <div className="input-group mb-3">
                        <h5>Lịch push </h5>
                        <DateTimePicker minDate={new Date()} value={this.state.timePick}  format="dd-MMM-yy HH:mm"  label="DateTimePicker"  onChange={(date) => {
                            let timeString = date + "";
                            if (date == null) timeString = ""; 
                            this.setState({
                                    timePick: date,
                                    timePush: timeString
                                })
                            }} />

                        </div>

                        <div className="input-group mb-3">
                            <select
                                value={this.state.language}
                                onChange={(event) => {
                                    this.setState({
                                        language: event.target.value
                                    })
                                }}
                                className="form-control">
                                <option value="ALL">ALL</option>
                                <option value="vi">IKARA</option>
                                <option value="en.yokara">YOKARA</option>
                            </select>
                        </div>

                        <div className="">
                            {this.state.loading ?
                                (
                                    <button style={{ width: "120px" }} className="btn btn-red float-right" type="button" disabled>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        ...
                                    </button>
                                ) :
                                (
                                    <button style={{ width: "120px" }} onClick={this.pushNotification} className="btn btn-red float-right" type="button">Push</button>
                                )}

                        </div>

                    </div>
                </div>
            </>
        );
    }
}