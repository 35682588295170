import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import bindModel from '../../../common/bindModel';

const ModalChangeStatus = ({ model, newStatus, resetList }) => {
    const [loading, setLoading] = useState(false);
    const [stateModel, setStateModel] = useState(null);
    const [date, setDate] = useState(null);
    const [stateNewStatus, setStateNewStatus] = useState(null);

    useEffect(() => {
        setStateModel(model);
        setStateNewStatus(newStatus);
    }, [model, newStatus]);


    const changeDate = (e) => {
        let date1 = e.target.value ;
        let dateAPI = Date.parse(date1)
        setDate(dateAPI)
    }

    const changeStatus = () => {
        console.log(stateModel);
        
        if (!stateModel || !stateNewStatus) return;

        let obj = {...stateModel};
        obj.status = stateNewStatus;
        obj.releaseDate = date;

        if (obj.status === 'DONE' && (obj.releaseDate == null || obj.releaseDate < new Date())) {
            toast.error('Nhập ngày release tương lai nếu chuyển DONE');
            return;
        }

        setLoading(true);

        axios.post(global.config.apiDomain + '/rest/admin/feature-request/changeStatus', JSON.stringify(obj), {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                setLoading(false);

                if (res.data.status === "OK") {
                    window.$("#modalChangeStatus").modal("hide");
                    toast.success(res.data.message);
                    resetList();
                } else {
                    toast.warning(res.data.message);
                }
            });
    };

    const bind = bindModel({ stateModel, setStateModel });

    return (
        <div>
            <div className="modal fade" id="modalChangeStatus" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog  modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                        {stateModel && stateNewStatus ? (
                            <>
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Chuyển trạng thái <span className='text-success'><strong>{stateModel.status}</strong></span> sang: <span className='text-success'><strong>{stateNewStatus}</strong></span></h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="row mb-2">
                                        <h3 className="text-center text-danger col-10">Chắc chắn CHUYỂN TRẠNG THÁI: <span className="font-weight-bold">{stateModel.name}</span> ?</h3>
                                    </div>
                                    {stateNewStatus === 'DONE' && (
                                        <div className="row mb-2">
                                            <div className="col-3">
                                                <label>Dự kiến release</label>
                                            </div>
                                            <div className="col-9">
                                                <input
                                                    className="form-control mb-2"
                                                    type="datetime-local"
                                                    onChange={(e => changeDate(e))}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </>
                        ) : null}
                        <div className="modal-footer">
                            <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                            {loading ? (
                                <button style={{ width: "120px" }} className="btn btn-red" type="button" disabled>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    Xác nhận...
                                </button>
                            ) : (
                                <button
                                    style={{ width: "120px" }}
                                    type="button"
                                    className="btn btn-red"
                                    onClick={changeStatus}
                                >
                                    Xác nhận
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalChangeStatus;
