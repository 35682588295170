import React, { Component } from 'react';
import axios from 'axios';
import ModalUpdateBOT from './Components/ModalUpdateBOT';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';

export default class BOTManagerPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            botlist: [],
            bot: null,
            loading: false
        }
        this.getActiveBOT = this.getActiveBOT.bind(this);
    }

    getActiveBOT() {
        let url = global.config.apiDomain + "/rest/bot-manager/get-active-bot"
        axios.post(url)
            .then(res => {
                this.setState({
                    botlist: res.data.botlist
                })
            })
    }

    componentDidUpdate() {
        const { botlist, bot } = this.state;
        botlist.map((bot, index) => {
            // console.log(screen)
        })

    }

    componentDidMount() {
        this.getActiveBOT();
    }

    render() {
        const { botlist, bot } = this.state;
        return (
            <>
                <ModalUpdateBOT bot={bot} botlist={botlist}></ModalUpdateBOT>
                <div id="content">
                    <div className="container-fluid">
                        <h1 className="text-center">Quản lí BOT</h1>
                        <table className="table table-hover">
                            <thead>
                                <tr className="text-center">
                                    <th className="align-middle" scope="col">STT</th>
                                    <th className="align-middle" scope="col">Facebook ID</th>
                                    <th className="align-middle" scope="col">UID</th>
                                    <th className="align-middle" scope="col">Name</th>
                                    <th className="align-middle" scope="col">Avatar</th>
                                    <th className="align-middle" scope="col">Tuổi</th>
                                    <th className="align-middle" scope="col">Giới tính</th>
                                    <th className="align-middle" scope="col">Trạng thái</th>
                                    <th className="align-middle" scope="col">Chỉnh sửa</th>
                                    <th className="align-middle" scope="col">Add bài thu</th>
                                    <th className="align-middle" scope="col">Add Following</th>


                                </tr>
                            </thead>
                            <tbody>
                                {botlist && botlist.map((item, index) => {
                                    return (
                                        <tr>
                                            <td className="align-middle text-center" scope="col">{index + 1}</td>
                                            <td className="align-middle text-center" scope="col">{item.id}</td>
                                            <td className="align-middle text-center" scope="col">{item.uid}</td>
                                            <td className="align-middle text-center" scope="col">{item.name}</td>
                                            <td className="align-middle text-center" scope="col">
                                                <img src={item.avatar} alt="" style={{ width: "100px" }} />
                                            </td>
                                            <td className="align-middle text-center" scope="col">{item.age}</td>
                                            <td className="align-middle text-center" scope="col">{item.gender}</td>
                                            <td className="align-middle text-center" scope="col">{item.signature}</td>
                                            <td className="align-middle text-center" scope="col">
                                                <button className='btn btn-info'
                                                    data-toggle="modal"
                                                    data-target="#ModalUpdateBOT"
                                                    onClick={() => {
                                                        this.setState({
                                                            bot: item
                                                        })
                                                    }}
                                                >Chỉnh sửa</button>
                                            </td>
                                            <td className="align-middle text-center" scope="col">
                                                <Link to={`/admin/bot-recording/${item.id}`} className="dropdown-item w-75">
                                                    <i class="fa-solid fa-music mr-2"></i>
                                                    ADD Bài thu
                                                </Link>
                                            </td>
                                            <td className="align-middle text-center" scope="col">
                                                <Link to={`/admin/botfollow/${item.id}`} className="dropdown-item w-75">
                                                    <i class="fa-solid fa-music mr-2"></i>
                                                    Add Following
                                                </Link>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        );
    }
}

