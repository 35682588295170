import React, { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import $ from 'jquery';
import bindModel from '../../../common/bindModel';
import { log } from 'tone/build/esm/core/util/Debug';


export default class VirtualBackgroundCreateOrUpdate extends Component {

    constructor(props) {
        super(props);

        this.state = {
            model: null,
            loading: false,
            isCreate: true,
        }
        this.uploadThumbnail = this.uploadThumbnail.bind(this);
        this.createOrUpdateVirtualBackground = this.createOrUpdateVirtualBackground.bind(this);
    }
    componentWillReceiveProps(props) {
        const { model, isCreate } = props;
        log(model)
        log(isCreate)
        this.setState({
            loading: false
        });

        if (model != null) {
            this.setState({
                model: model,
                isCreate: isCreate
            })
        }else{
            this.setState({
                isCreate: isCreate
            })
        }
    }

    uploadThumbnail(event) {
        this.setState({
            loading: true
        });
        var model = this.state.model;
        var target = event.target;
        var bucketName = "ikara-data/images/virtualBackground";
        var extension = target.files[0].name.split('.').pop().toLowerCase();
        var keyName = this.guid() + "." + extension;
        var contentType = "image/jpeg";
        if (extension == "png") contentType = "image/png";
        let file = event.target.files[0];

        var fileReader = new FileReader();
        fileReader.onload = function () {
            var fd = new FormData();
            fd.append('file', file);
            fd.append('bucketName', bucketName);
            fd.append('objectKey', keyName);
            fd.append('contentType', contentType);
            log(fd)
            $.ajax({
                url: global.config.apiDomain + '/web.UploadFile',
                data: fd,
                processData: false,
                contentType: false,
                type: 'POST',
                success: function (data) {
                    this.setState({
                        loading: false
                    });

                    model.url = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                    this.setState({
                        model: model,
                    });

                }.bind(this),
                error: function(xhr, status, error) {
                    console.error('Error:', error);
                    this.setState({
                        loading: false,
                    });
                    toast.warning('lỗi server, please try again');

                }.bind(this)
            });
        }.bind(this);
        fileReader.readAsArrayBuffer(file);
    }

    createOrUpdateVirtualBackground() {
        let obj = { ...this.state.model };
        console.log(obj);
        this.setState({
            loading: true
        });
        axios.post(global.config.apiDomain + "/rest/virtualBackground/createOrUpdate", obj, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                console.log(res);

                this.setState({
                    loading: false
                });
                if (res.data && res.data.status == "OK") {
                    window.$("#modalCreate").modal("hide");
                    toast.success(res.data.message);
                    this.props.resetList();
                } else {
                    toast.error(res.data.message)
                }
            })
    }
    guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

    handleCheckboxChange = (e) => {
        const updatedModel = { ...this.state.model, isActive: e.target.checked };
        this.setState({ model: updatedModel });
    }

    render() {
        const bind = bindModel(this);
        const { model } = this.state;
        return (
            <div>
                <div className="modal fade" id="modalCreate" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalCenterTitle">{this.state.isCreate ?  "Thêm mới Virtual Background" : "Cập nhật Virtual Background"}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>

                            <div className="modal-body">
                                <div id="id" className="row mb-2">
                                    <div className="col-3">
                                        <label>ID</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="text"
                                            name="id"
                                            {...bind('model.id')}
                                            placeholder="Nhập ID..."
                                            disabled={!this.state.isCreate}
                                        />
                                    </div>
                                </div>


                                <div id="id" className="row mb-2">
                                    <div className="col-3">
                                        <label>Tên background</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="text"
                                            name="id"
                                            {...bind('model.name')}
                                            placeholder="Nhập tên quà..."
                                        />
                                    </div>
                                </div>

                                <div id="id" className="row mb-2">
                                    <div className="col-3">
                                        <label>Level yêu cầu</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="text"
                                            name="id"
                                            {...bind('model.levelRequirement')}
                                            placeholder="Nhập level..."
                                        />
                                    </div>
                                </div>

                                <div id="id" className="row mb-2">
                                    <div className="col-3">
                                        <label>Level VIP yêu cầu</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="text"
                                            name="id"
                                            {...bind('model.levelVipRequirement')}
                                            placeholder="Nhập level VIP..."
                                        />
                                    </div>
                                </div>
                                <div id="status" className="row mb-2">
                                <div className="col-3">
                                    <label>Trạng thái</label>
                                </div>
                                <div className="col-9">
                                    {model ? (
                                        <>
                                            <input
                                                type="checkbox"
                                                checked={model.isActive || false}
                                                onChange={(e) => this.handleCheckboxChange(e)}
                                            />
                                            <label className="ml-2">{model.isActive ? "Đang hiển thị" : "Đang ẩn"}</label>
                                        </>
                                    ) : (
                                        <span>Chưa có dữ liệu</span>
                                    )}
                                </div>
                            </div>
                                <div id="avatar" className="row mb-2">
                                    <div className="col-3">
                                        <label>Ảnh</label>
                                    </div>
                                    <div className="col-9">
                                        <input id="url"
                                            className="custom-file-input"
                                            type="file"
                                            onChange={this.uploadThumbnail} />
                                        <label className="custom-file-label ml-3 mr-3" htmlFor="inputGroupFile03">Choose file</label>
                                    </div>
                                </div>
                                {model && model.url ?
                                    <div id="reviewThumbnailUrl" className="row mb-2">
                                        <div className="col-3">
                                        </div>
                                        <div id="show-thumbnailUrl" className="col-9">
                                            <img src={model.url} width="200px" />
                                        </div>
                                    </div> : ''}

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                                {this.state.loading ?
                                    (
                                        <button style={{ width: "120px" }} className="btn btn-red" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Xác nhận...
                                        </button>
                                    ) :
                                    (
                                        <button style={{ width: "120px" }} type="button" className="btn btn-red"
                                            onClick={this.createOrUpdateVirtualBackground}
                                        >Xác nhận</button>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}