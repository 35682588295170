import React, { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

export default class ModalAddPartner extends Component {

    constructor(props) {
        super(props);

        this.state = {
            uid: null,
            loading: false,
        }

        this.handleCreate = this.handleCreate.bind(this);
    }

    handleCreate() {
        const { uid } = this.state;
        const json = {
            uid: uid,
            role: 'COLLABORATOR'
        }
        axios
            .post(
                global.config.apiDomain + "/rest/privilegeAdmin/create",
                json
            )
            .then((res) => {
                if (res.data.status == "FAILED") {
                    toast.warning(res.data.message);
                } else {
                    toast.success(res.data.message);
                }
                this.setState({
                    uid: null,
                });

                window.$("#modalAddPartner").modal("hide");
                this.props.resetList();
            })
            .catch((err) => {
                toast.warning("Thêm thất bại ");
            });
    }

    render() {
        return (
            <div>
                <div className="modal fade" id="modalAddPartner" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalCenterTitle">Thêm Cộng Tác Viên</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div id="content">
                                <div className="container p-5">
                                    <h2 className="m-3 text-center">Thêm Cộng Tác Viên</h2>
                                    <div className="input-group mb-3">
                                        <input
                                            value={this.state.uid}
                                            onChange={(event) => {
                                                this.setState({
                                                    uid: event.target.value
                                                })
                                            }}
                                            type="text"
                                            className="form-control"
                                            placeholder="Nhập uid" />
                                        <a
                                            style={{
                                                height: "20px!important",
                                                width: "70px",
                                                padding: "7px 0 0 11px",
                                                background: "#25A0FD",
                                                marginLeft: '11px'
                                            }}
                                            onClick={this.handleCreate}
                                        >Thêm</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}