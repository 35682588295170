import React, { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import bindModel from '../../../common/bindModel.js';
const $ = window.$;

export default class ModalReceivingHistory extends Component {
    constructor(props) {
        super(props);

        this.state = {
            cursor: null,
            gifts: null,
            fromDate: null,
            toDate: null,
            statusButtonLoadPage: false,
            loading: false
        }

        this.getFirstHistory = this.getFirstHistory.bind(this);
        this.searchFirstHistory = this.searchFirstHistory.bind(this);
        this.loadMoreHistory = this.loadMoreHistory.bind(this);
        this.reset = this.reset.bind(this);
    }

    loadMoreHistory() {
        this.setState({
            loading: true
        });

        let fromDate = null;
        let toDate = null;
        try {
            fromDate = Date.parse(this.state.fromDate);
        } catch (error) {
            fromDate = null;
        }

        try {
            toDate = Date.parse(this.state.toDate);
        } catch (error) {
            toDate = null;
        }

        let json = {
            receiverFacebookId: this.props.account.id,
            cursor: this.state.cursor,
            fromDate: fromDate,
            toDate: toDate
        };
        console.log(json);
        axios.post(global.config.apiDomain + '/rest/admin/showHistoryReceiverIcoin', JSON.stringify(json), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            let status = true;

            if (res.data.receiverGifts.length < 50) {
                status = false;
            }

            this.setState({
                gifts: this.state.gifts.concat(res.data.receiverGifts),
                cursor: res.data.cursor,
                statusButtonLoadPage: status,
                loading: false
            });
        })

    }

    searchFirstHistory() {
        this.setState({
            cursor: null
        });
        this.getFirstHistory(this.props.account)
    }

    getFirstHistory(account) {
        if (account != null) {
            let fromDate = null;
            let toDate = null;
            try {
                fromDate = Date.parse(this.state.fromDate);
            } catch (error) {
                fromDate = null;
            }
    
            try {
                toDate = Date.parse(this.state.toDate);
            } catch (error) {
                toDate = null;
            }
            let json = {
                receiverFacebookId: account.id,
                fromDate: fromDate,
                toDate: toDate
            }
    
            axios.post(global.config.apiDomain + '/rest/admin/showHistoryReceiverIcoin', JSON.stringify(json), {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                console.log('showHistoryReceiverIcoin');
                console.log(res);
                let status = true;
                if (res.data.receiverGifts.length < 50) {
                    status = false;
                }
                this.setState({
                    gifts: res.data.receiverGifts,
                    cursor: res.data.cursor,
                    statusButtonLoadPage: status,
                    loading: false
                });
            })
        }

    }

    componentDidUpdate() {
        console.log('componentDidUpdate ======== ModalReceivingHistory');
        if (this.state.gifts == null) {
            const { account } = this.props;
            this.getFirstHistory(account);
        }
    }

    reset() {
        this.setState({
            fromDate: "",
            toDate: "",
            cursor: null
        });
        this.getFirstHistory(this.props.account);
    }


    render() {
        const model = bindModel(this);
        const { gifts, statusButtonLoadPage, loading } = this.state;

        return (
            <div>
                <br />
                <div className="input-group">
                    <input
                        className="form-control col-md-2 col-sm-6"
                        type="datetime-local"
                        name="fromDate"
                        placeholder="Từ ngày"
                        {...model('fromDate')}
                    />
                    <input
                        className="form-control col-md-2 col-sm-6"
                        type="datetime-local"
                        name="toDate"
                        placeholder="Đến ngày"
                        {...model('toDate')}
                    />
                    <div className="input-group-append">
                        <button
                            className="btn btn-outline-success"
                            type="button"
                            id="button-addon2"
                            onClick={this.searchFirstHistory}>
                            <i className="fa fa-search"></i>
                        </button>
                        <button
                            className="btn btn-outline-danger"
                            type="button"
                            id="button-addon2"
                            onClick={this.reset}>
                            <i className="fas fa-sync"></i>
                        </button>
                    </div>
                </div><br />

                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th className="align-middle text-center" scope="col" rowSpan="2">Ngày nhận</th>
                            <th className="text-center" scope="col" colSpan="3">Người tặng</th>
                            <th className="text-center" scope="col" colSpan="6">Quà</th>
                            <th className="align-middle text-center" scope="col" rowSpan="2">Recording/LiveRoom</th>
                        </tr>
                        <tr>
                            <th className="text-center" scope="col">UID</th>
                            <th className="text-center" scope="col">Tên</th>
                            <th className="text-center" scope="col">Avatar</th>
                            <th className="text-center" scope="col">ID</th>
                            <th className="text-center" scope="col">Tên</th>
                            <th className="text-center" scope="col">Ảnh</th>
                            <th className="text-center" scope="col">Số lượng</th>
                            <th className="text-center" scope="col">Giá mua</th>
                            <th className="text-center" scope="col">Giá bán</th>
                        </tr>
                    </thead>
                    <tbody>
                        {gifts ? (
                            gifts.map((record, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{new Date(record.addTime).toLocaleString()}</td>
                                        <td>{record.paymentDetail.user.uid}</td>
                                        <td>{record.paymentDetail.user.name}</td>
                                        <td>
                                            <img src={record.paymentDetail.user.profileImageLink} height={100} width={100} />
                                        </td>
                                        <td>{record.paymentDetail.gift.id}</td>
                                        <td>{record.paymentDetail.gift.name}</td>
                                        <td>
                                            <img src={record.paymentDetail.gift.url} height={100} width={100} />
                                        </td>
                                        <td>{record.paymentDetail.gift.noItem}</td>
                                        <td>{record.paymentDetail.gift.buyPrice}</td>
                                        <td>{record.paymentDetail.gift.sellPrice}</td>

                                        {record.paymentDetail.recording ? (
                                            <td><a href={record.paymentDetail.recording.onlineRecordingUrl}>{record.paymentDetail.recording.yourName}</a></td>
                                        ) : record.paymentDetail.liveRoom ? (
                                            <td>{record.paymentDetail.liveRoom.uid}</td>
                                        ) : record.linkRecoding_liveRoomId? record.linkRecoding_liveRoomId : ''}

                                    </tr>
                                )
                            })
                        ) : <></>}

                    </tbody>
                </table>

                {
                    statusButtonLoadPage ?
                        (<div onClick={this.loadMoreHistory} className="view_more w-100 text-center mb-lg-4">
                            <a className="d-none d-md-block">
                                <span>{loading ? "ĐANG TẢI ..." : "TẢI THÊM"}</span>
                            </a>
                            <a className="view_more_mobile d-md-none">
                                <i className="fa fa-angle-down" aria-hidden="true" />
                            </a>
                        </div>) :
                        ('')
                }
            </div>
        )
    }
}