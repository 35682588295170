import React, { Component } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
// import bindModel from '../../../common/bindModel.js';
export default class ModalCreateUpdateCopyright extends Component {
    constructor(props) {
        super(props);

        this.state = {
            CopyrightModels: [],
            relatedWords: [],
            relatedWordVietnameses: [],
            relatedWordVietnamese: '',
            relatedWord: '',
            copyright: '',
            blockWord:'',
            blockWordVietnamese: '',
            loading: false,
            id: 0,
            user: ''
        }
        // this.addFollow = this.addFollow.bind(this);
    }

    componentWillReceiveProps(props) {
        // $("#iconPreview").children("svg").remove();

        let id = props.id;
        let relatedWordVietnameses = props.relatedWordVietnameses
        let blockWordVietnamese = props.blockWordVietnamese
        let relatedWords = []
        console.log(relatedWordVietnameses);
        if(Array.isArray(relatedWordVietnameses)){
              relatedWordVietnameses.map((relatedWordVietnamese, index) => {
            // console.log(screen)
            relatedWords.push(this.removeDiacritics(relatedWordVietnamese))
        })
    }
        let blockWord = this.removeDiacritics(blockWordVietnamese)
        this.setState({
            relatedWordVietnameses: relatedWordVietnameses,
            blockWordVietnamese: blockWordVietnamese,
            id: id,
            blockWord:blockWord,
            relatedWords :relatedWords
        })


    }
    removeDiacritics(str) {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/đ/g, "d") // Thay thế chữ đ thường thành d
            .replace(/Đ/g, "D");;
    }
    handleRelatedWord = (e) => {
        let value = e.target.value;
        this.setState({
            relatedWord: value
        })
    }
    handleBlockWord = (e) => {
        let value = e.target.value;
        this.setState({
            blockWord: value
        })
    }
    handlerelatedWord = (e) => {
        let value = e.target.value;
        console.log(value);
        this.setState({
            relatedWordVietnamese: value
        })
    }
    removeRelated = (e) => {
        e.preventDefault();
        let index = e.target.value
        console.log(index);

        let relatedWordVietnameses = [...this.state.relatedWordVietnameses]
        let relatedWords = [...this.state.relatedWords]
        // console.log(relatedWordVietnameses);
        relatedWordVietnameses.splice(index, 1)
        console.log(relatedWordVietnameses);
        relatedWords.splice(index, 1)
        this.setState({
            relatedWordVietnameses: relatedWordVietnameses,
            relatedWords: relatedWords
        })
    }
    addrelatedWords = (e) => {
        e.preventDefault();
        var relatedWordVietnamese = this.state.relatedWordVietnamese
        var relatedWordVietnameses = [...this.state.relatedWordVietnameses]
        var relatedWords = [...this.state.relatedWords]
        console.log(relatedWordVietnamese)
        let relatedWord = this.removeDiacritics(relatedWordVietnamese)
        relatedWords.push(relatedWord)
        console.log(relatedWords);

        console.log(relatedWord);



        if (relatedWordVietnamese) {
            relatedWordVietnameses.push(relatedWordVietnamese)
            this.setState({
                relatedWordVietnameses: relatedWordVietnameses,
                relatedWords : relatedWords,
                relatedWordVietnamese:''
            }
            )
        }
        else {
            toast.warning("vui lòng nhập từ khoá")
        }
    }
    addCopyright = () => {
        this.setState({
            loading: true

        })
        let relatedWordVietnameses = [...this.state.relatedWordVietnameses]
        let relatedWords = [...this.state.relatedWords]
        let blockWord = this.state.blockWord
        let blockWordVietnamese = this.state.blockWordVietnamese
        let id = this.state.id
        if (relatedWordVietnameses.length == 0 || blockWordVietnamese == '') {
            toast.warning("Vui lòng nhập đầy đủ thông tin !")
            return
        }
        axios.post(global.config.apiDomain + "/rest/copyright/create", {
            Id: id,
            blockWord: blockWord,
            relatedWords: relatedWords,
            blockWordVietnamese: blockWordVietnamese,
            relatedWordVietnamese: relatedWordVietnameses
        })
            .then(res => {
                if (res.data.status == "OK") {
                    this.setState({
                        loading: false,
                        relatedWordVietnameses: [],
            blockWordVietnamese: [],
            id: 0,
            blockWord:'',
            relatedWords :''
                    });
                    window.$("#modalUpdateVipPrivilege").modal("hide");
                    this.props.resetList();
                    toast.success(res.data.message);
                }
                else{
                    toast.error(res.data.message);
                }
            }, error => {
                this.setState({
                    loading: false
                });
                toast.error(error);
            })
    }


    render() {
        // const model = bindModel(this);
        const { loading, relatedWordVietnameses } = this.state;

        return (
            <div className="modal fade" id="modalUpdateVipPrivilege" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalCenterTitle">Thêm từ khoá bản quyền </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div id="thumbnailUrl" className="row mb-2">
                                <div className="col-3">
                                    <label>Từ khoá bản quyền</label>
                                </div>
                                <div className="col-9">
                                    <input
                                        type="text"
                                        className="form-control mb-2"
                                        placeholder="Từ khoá bản quyền"
                                        value={this.state.blockWordVietnamese}
                                        onChange={(e) => {
                                            console.log("vaof ")
                                            let valueVietnamese = e.target.value;
                                            console.log(value);
                                            let value = this.removeDiacritics(valueVietnamese)
                                            this.setState({
                                                blockWordVietnamese: valueVietnamese,
                                                blockWord : value
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                            <div id="thumbnailUrl" className="row mb-2">
                                <div className="col-3">
                                    <label>Từ khoá liên quan</label>
                                </div>
                                <div className="col-9">
                                    <input
                                        type="text"
                                        className="form-control mb-2"
                                        placeholder="Từ khoá liên quan"
                                        value={this.state.relatedWordVietnamese}
                                        onChange={(e) => {
                                            let value = e.target.value;
                                            console.log(value);
                                            this.setState({
                                                relatedWordVietnamese: value
                                            })
                                            this.handleRelatedWord(e)
                                        }}
                                    />
                                    <button
                                        onClick={(e) => this.addrelatedWords(e)}
                                    >Thêm từ khoá</button>
                                </div>

                            </div>
                            <div id="thumbnailUrl" className="row mb-2">
                                <div className="col-3">
                                    <label>Danh sách từ khoá liên quan</label>
                                </div>
                                <div className="col-9">
                                    {
                                        Array.isArray(relatedWordVietnameses) && relatedWordVietnameses.map((item, index) => {
                                            return (<div className="row mb-2">
                                                <label className="col-3 ml-2">Từ số {index + 1} : </label>
                                                <p className="col-6 ml-1">{item}</p>
                                                <button className='btn-danger'
                                                    style={{ height: 'auto', width: 'auto' }}
                                                    onClick={(e) => this.removeRelated(e)}
                                                    value={index}>Xóa</button>
                                            </div>
                                            )
                                        })}
                                </div>
                            </div>
                            <div className="modal-footer">

                                <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                                {loading ?
                                    (
                                        <button style={{ width: "120px" }} className="btn btn-red" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Xác nhận...
                                        </button>
                                    ) :
                                    (
                                        <button style={{ width: "120px" }} type="button" className="btn btn-red" onClick={this.addCopyright}>Xác nhận</button>
                                    )}
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        );
    }
}