import React, { Component } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import bindModel from '../../../common/bindModel.js';
export default class BotFollow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            botlist: [],
            bot: '',
            loading: false,
            user : ''
        }
        this.addFollow = this.addFollow.bind(this);
    }

    componentWillReceiveProps(props) {
        // $("#iconPreview").children("svg").remove();

        let recording = props.recording;
        let bot = props.bot
        this.setState({
            bot: bot,
        })


    }
        handleUser(e) {
            let value = e.target.value;
            this.setState({
                user: value
            })
        }
        addFollow = () => {
            this.setState({
                loading: true
            })
            var toUser = this.state.user;
            var fromUser = this.state.bot;
            if(toUser == ''){
                toast.warning("Vui lòng nhập id người dùng ")
                this.setState({
                    loading: false
                })
                return
            }
            else{
                axios.post(global.config.apiDomain + "/rest/bot-manager/add-bot-follow", {
                    botId : fromUser ,
                    userId: toUser
                })
                    .then(res => {
                        if (res.data.status == "OK") {
                            this.setState({
                                loading :false
                            })
                            toast.success(res.data.message);

                            window.$("#modalUpdateVipPrivilege").modal("hide");
                            this.props.resetList();
                            console.log(res.data)
                        }
                        else{
                            toast.warning(res.data.message);
                            this.setState({
                            loading: false
                        });
                        }
                    }, error => {
                        this.setState({
                            loading: false
                        });
                        toast.error(error);
                    })
            }
        }
    
    render() {
        const model = bindModel(this);
        const { loading, user} = this.state;

        return (
                <div className="modal fade" id="modalUpdateVipPrivilege" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalCenterTitle">ADD Follow</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">                         
                                <div id="thumbnailUrl" className="row mb-2">
                                    <div className="col-3">
                                        <label>ID USER</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            type="text"
                                            className="form-control mb-2"
                                            placeholder="ENTER USERID"
                                            onChange={(e) => this.handleUser(e)}
                                        />
                                    </div>
                                </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                                {loading ?
                                    (
                                        <button style={{ width: "120px" }} className="btn btn-red" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Xác nhận...
                                        </button>
                                    ) :
                                    (
                                        <button style={{ width: "120px" }} type="button" className="btn btn-red" onClick={this.addFollow}>Xác nhận</button>
                                    )}
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        );
    }
}