import React, { useState, useEffect } from "react";
import axios from "axios";

const player = {
  "playerGameInfo": [
      {
          "id": 6137655888773120,
          "gameId": "415c9a88-c635-4bcb-8018-d799a08c3faa",
          "addTime": 1725603704105,
          "dateTime": null,
          "fbId": "imbot4",
          "name": "Alice Huynh",
          "small": 0,
          "big": 0,
          "triple": 500
      },
      {
          "id": 5987364379885568,
          "gameId": "415c9a88-c635-4bcb-8018-d799a08c3faa",
          "addTime": 1725603704073,
          "dateTime": null,
          "fbId": "imbot36",
          "name": "Gia Hao",
          "small": 0,
          "big": 0,
          "triple": 200
      }
  ]
}

const game = {
  "gameInfo": [
    {
        "id": 6131979082858496,
        "gameId": "abe809c7-04cf-4402-8dec-dee6b2e4c395",
        "addTime": 1725604369277,
        "dateTime": 1725604356922,
        "gate": "SMALL",
        "dice1": 6,
        "dice2": 2,
        "dice3": 1
    },
    {
        "id": 4645691490107392,
        "gameId": "2356563c-adac-4c85-8d4d-f5f810003c5e",
        "addTime": 1725604336174,
        "dateTime": 1725604323540,
        "gate": "SMALL",
        "dice1": 1,
        "dice2": 6,
        "dice3": 1
    }]
}

function convertTimestampToVietnamTime(timestamp) {
  const date = new Date(timestamp);
  const options = {
    timeZone: 'Asia/Ho_Chi_Minh',
    hour: '2-digit',
    minute: '2-digit',
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  };

  return date.toLocaleString('vi-VN', options);
}

function DiceGameInfoHistory() {
  const [gameInfo, setGameInfo] = useState([]);
  const [playerInfo, setPlayerInfo] = useState([]);

  const [selectedDate, setSelectedDate] = useState(0);
  const [loading, setLoading] = useState(null);

  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = (gameId) => {
    setShowModal(true);
    getGamePlayerInfo(gameId)
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  async function getGameInfo(startDate) {
    const params = {startDateMillis: startDate};
    setLoading(true);

    await axios.get(global.config.apiDomain + '/rest/dice-game/get-dice-game-info', {
      params: params
    })
    .then(res => {
      if (res.data.status = "OK") {
        setGameInfo(res.data.data.gameInfo);
      } else {
        alert("Có lỗi", res.data.message);
      }      
    })
    .catch(error => {
        console.error("Error:", error);
    });
    setLoading(false);
  }

  async function getGamePlayerInfo(gameId) {
    const params = {};
    
    if (gameId) params.gameId = gameId;

    setLoading(true);

    await axios.get(global.config.apiDomain + '/rest/dice-game/get-player-dice-game-info', {
      params: params
    })
    .then(res => {
      if (res.data.status = "OK") {
        setPlayerInfo(res.data.data.playerGameInfo);
      } else {
        alert("Có lỗi", res.data.message);
      } 
      
    })
    .catch(error => {
        console.error("Error:", error);
    });
    setLoading(false);
  }

  const fetchLogs = async () => {
    try {
      if (!selectedDate) {
        alert("Vui lòng chọn ngày cần kiểm tra");
        return;
      }
      console.log(selectedDate);

      const miliDate = new Date(selectedDate).getTime();
      getGameInfo(miliDate)

      return;
    } catch (error) {
      alert("Có lỗi xảy ra");
    }
  };

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, []);

  return (
    <>
      <div
        className="container"
        style={{
          marginTop: "100px",
          marginBottom: "100px",
        }}
      >
        <h1 className="text-center">Dice Game History</h1>
        <div className="filter-container">
          <label htmlFor="time-search" style={{paddingRight:"50px"}}>Chọn ngày cần kiểm tra</label>
          <input
            id="time-search-start"
            type="date"
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
            placeholder="Nhập time"
          />
          <br />
          <button className="btn" onClick={fetchLogs}>
            Tìm Kiếm
          </button>

        </div>
        { 
          loading === null ? (
            <h1>Lựa chọn ngày để tra cứu data</h1>
          ) : loading === true ? (
            <h1>Đang tải dữ liệu...</h1>
          ) : (
            <table
              className="table table-bordered table-hover"
              style={{ marginTop: "20px" }}
            >
              <thead className="table-primary">
                <tr>
                  <th onClick={handleOpenModal}>No</th>
                  <th>gameId</th>
                  <th>Time</th>
                  <th>Gate</th>
                  <th>dice1</th>
                  <th>dice2</th>
                  <th>dice3</th>
                  <th>Note</th>
                </tr>
              </thead>
              <tbody>
                {gameInfo && gameInfo.length > 0 ? (
                  gameInfo.map((game, index) => (
                    <tr key={game.gameId}>
                      <td>{index}</td>
                      <td>{game.gameId}</td>
                      <td>{convertTimestampToVietnamTime(game.dateTime)}</td>
                      <td>{game.gate}</td>
                      <td>{game.dice1}</td>
                      <td>{game.dice2}</td>
                      <td>{game.dice3}</td>
                      <td onClick={() => handleOpenModal(game.gameId)}>Chi tiết</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={8}>Không có dữ liệu</td>
                  </tr>
                )}
              </tbody>
            </table>
          )
        }


{showModal && (
  <div
    className="modal fade show"
    tabIndex="-1"
    style={{ display: "block" }}
    role="dialog"
  >
    <div
      className="modal-dialog modal-lg modal-dialog-scrollable"
      style={{ maxWidth: "90%" }}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Lịch sử Người chơi trong ván đấu</h5>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={handleCloseModal}
          ></button>
        </div>
        <div className="modal-body">
          {loading ? (
            <h5>Đang tải dữ liệu...</h5>
          ) : (
            <table
              className="table table-bordered table-hover"
              style={{ marginTop: "20px", tableLayout: "fixed" }}
            >
              <thead className="table-primary">
                <tr>
                  <th>No</th>
                  <th style={{ width: "200px" }}>Time</th>
                  <th style={{ width: "300px" }}>FbId</th>
                  <th style={{ width: "300px" }}>Name</th>
                  <th>Small</th>
                  <th>Big</th>
                  <th>Triple</th>
                  <th>Kết quả</th>
                </tr>
              </thead>
              <tbody>
                {playerInfo && playerInfo.length > 0 ? (
                  playerInfo.map((player, index) => (
                    <tr key={player.id}>
                      <td>{index + 1}</td>
                      <td>{convertTimestampToVietnamTime(player.addTime)}</td>
                      <td>{player.fbId}</td>
                      <td>{player.name}</td>
                      <td>{player.small}</td>
                      <td>{player.big}</td>
                      <td>{player.triple}</td>
                      <td>{player.result}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7} className="text-center">
                      Không có dữ liệu
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={handleCloseModal}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
)}



      </div>
    </>
  );
}

export default DiceGameInfoHistory;
