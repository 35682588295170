import React, { Component } from 'react';
import bindModel from '../../../common/bindModel.js';
import axios from 'axios';
import { toast } from 'react-toastify';
import SVGA from 'svgaplayerweb'
import $ from 'jquery';

export default class ModalUpdateBOT extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bot: null,
            loading: false,
        }
        this.updateBOT = this.updateBOT.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
    }

    reloadPage() {
        window.location.reload();
    }

    updateBOT() {

        const { bot } = this.state;

        if (bot.name == null || "" == bot.name) {
            toast.warning("Vui lòng thêm tên BOT")
        }

        if (bot.uid == null || "" == bot.uid) {
            toast.warning("Vui lòng thêm UID BOT")
        }

        // if (bot.signature == null || "" == bot.signature) {
        //     toast.warning("Vui lòng thêm trạng thái BOT")
        // }

        if (bot.avatar == null || "" == bot.avatar) {
            toast.warning("Vui lòng thêm ảnh đại diện BOT")
        }

        let url = global.config.apiDomain + "/rest/bot-manager/update-bot"
        axios.post(url, bot)
            .then(res => {
                if (res.data.status == "FAILED") {
                    toast.warning(res.data.message);
                }
                else if (res.data.status == "OK") {
                    toast.success(res.data.message)
                    window.$("#ModalUpdateBOT").modal('hide')
                    setTimeout(() => {
                        this.reloadPage();
                    }, 2000);
                }
            })

    }

    uploadFile(event) {
        var target = event.target;
        var allowedImageTypes = ["image/jpeg", "image/png", "image/gif"];

        if (!allowedImageTypes.includes(target.files[0].type)) {
            toast.warn("Vui lòng chọn một file ảnh hợp lệ (JPEG, PNG, hoặc GIF).");
            return;
        }

        this.setState({
            loading: true
        });

        var id = target.id;
        var bucketName = "ikara-data";
        var keyName = "avatars/" + target.files[0].name;
        var contentType = target.files[0].type;

        var self = this;
        var fileReader = new FileReader();

        fileReader.onload = function () {
            var fd = new FormData();
            fd.append('file', target.files[0]);
            fd.append('bucketName', bucketName);
            fd.append('objectKey', keyName);
            fd.append('contentType', contentType);

            $.ajax({
                url: global.config.apiDomain + '/web.UploadFile',
                data: fd,
                processData: false,
                contentType: false,
                type: 'POST',
                success: function (data) {
                    console.log(data);
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                    let property = target.name;

                    let bot = this.state.bot;

                    var imageUrl = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;

                    this.setState({
                        bot: {
                            ...bot,
                            [property]: imageUrl,
                        }
                    });
                    $("#previewImg").children().replaceWith(`<img src=${'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName}
                    style={{maxHeight : "300px"}} ></img>`)
                    toast.success("Tải ảnh lên thành công.");
                    console.log(imageUrl);
                }.bind(this),
                error: function (jqXHR, exception) {
                    toast.error("Lỗi khi tải lên ảnh.");
                    console.error(jqXHR, exception);
                    this.setState({
                        loading: false
                    });
                }.bind(this),
            });
        }.bind(this);

        fileReader.readAsArrayBuffer(target.files[0]);
    }



    componentWillReceiveProps(props) {
        const { bot } = props;

        if (bot == null) {
            let json = {
                avatarUrl: ""
            }
            this.setState({
                bot: json,
                loading: false
            })

        }

        this.setState({
            bot: bot
        })
        if (bot != null) {

            if (bot.avatar) {
                $("#previewImg").children().replaceWith(`<img src=${bot.avatar}
                style={{maxHeight : "300px"}} ></img>`)
            }

            bot && bot.deleted ? $("#deletedSelect").val(0) : $("#deletedSelect").val(1)

        }
    }

    render() {
        const model = bindModel(this);
        const { bot, loading } = this.state;
        return (
            <div>
                <div className="modal fade" id="ModalUpdateBOT" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalCenterTitle">Cập nhật thông tin BOT</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.reloadPage}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div id="BOTFid" className="row mb-2">
                                    <div className="col-3">
                                        <label>Facebook ID</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="text"
                                            name="order"
                                            readOnly="true"
                                            {...model('bot.id')} />
                                    </div>
                                </div>
                                <div id="BOTUid" className="row mb-2">
                                    <div className="col-3">
                                        <label>UID</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="text"
                                            name="order"
                                            placeholder="Nhập UID"
                                            {...model('bot.uid')} />
                                    </div>
                                </div>
                                <div id="BOTName" className="row mb-2">
                                    <div className="col-3">
                                        <label>Tên BOT</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="text"
                                            name="order"
                                            placeholder="Nhập tên BOT"
                                            {...model('bot.name')} />
                                    </div>
                                </div>
                                <div id="age" className="row mb-2">
                                    <div className="col-3">
                                        <label>Tuổi</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="number"
                                            name="order"
                                            placeholder="Nhập tuổi"
                                            {...model('bot.age')} />
                                    </div>
                                </div>
                                <div id="gender" className="row mb-2">
                                    <div className="col-3">
                                        <label>Giới tính</label>
                                    </div>
                                    <div className="col-9">
                                        <select
                                            className="form-control mb-2"
                                            name="order"
                                            {...model('bot.gender')}
                                        >
                                            <option value="">Chọn giới tính</option>
                                            <option value="male">Nam</option>
                                            <option value="female">Nữ</option>
                                        </select>
                                    </div>
                                </div>

                                <div id="signature" className="row mb-2">
                                    <div className="col-3">
                                        <label>Trạng thái</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="text"
                                            name="order"
                                            placeholder="Nhập trạng thái"
                                            {...model('bot.signature')} />
                                    </div>
                                </div>
                                <div id="avatarUrl" className="row mb-2">
                                    <div className="col-3">
                                        <label>Ảnh đại diện</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            type="file"
                                            className="custom-file-input"
                                            name="avatar"
                                            onChange={this.uploadFile}
                                        />
                                        <label className="custom-file-label ml-3 mr-3" htmlFor="miniBackgroundUrl">Choose file</label>
                                    </div>
                                </div>
                                <div id="previewThumbnail" className="row mb-2">
                                    <div className="col-3">

                                    </div>
                                    <div className="col-9" id="previewImg">
                                        <div></div>
                                    </div>
                                </div>
                            </div>
                            <div className='modal-footer'>
                                <button className='btn btn-secondary ml-2 mr-2' data-dismiss="modal" onClick={this.reloadPage}>Hủy</button>
                                {loading ?
                                    (
                                        <button style={{ width: "120px" }} className="btn btn-red" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Xác nhận...
                                        </button>
                                    ) :
                                    (
                                        <button style={{ width: "120px" }} type="button" className="btn btn-red" onClick={this.updateBOT}>Xác nhận</button>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}