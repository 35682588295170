import React, { useState } from "react";
import axios from "axios";

function LiveRoomInfo() {
  const [uid, setUid] = useState(0);
  const [liveRoom, setLiveRoom] = useState();

  const [loading, setLoading] = useState(null);

  async function getLiveRoom() {

    let id = window.localStorage.getItem("userId");

    try {
        
      setLoading(true);

      await axios.get(`${global.config.apiDomain}/rest/live-room/get-live-room`, {
        params: { uid: uid, id: id}
      })
      .then(res => {
        if (res.data.status === "OK") {
          setLiveRoom(res.data.data.liveRoom);
        } else {
          alert("Có lỗi", res.data.message);
        }      
      })
      .catch(error => {
          console.error("Error:", error);
      });
      setLoading(false);
        
      } catch (error) {
        
      }

  }

  async function setTalkRoom() {

    let id = window.localStorage.getItem("userId");

    try {
    
      setLoading(true);
  
      await axios.put(`${global.config.apiDomain}/rest/live-room/set-talk-room`, null, {
        params: { uid: uid, id: id }
      })
        .then(res => {
          if (res.data.status === "OK") {
            alert("Thành công");
            getLiveRoom();
          } else {
            alert("Có lỗi", res.data.message);
          } 
        })
        .catch(error => {
          console.error("Error:", error);
        });
      setLoading(false);
      
    } catch (error) {
      
    }


  }

  return (
    <>
      <div
        className="container"
        style={{
          marginTop: "100px",
          marginBottom: "100px",
        }}
      >
        <h1 className="text-center">Live Room</h1>
        <div className="filter-container">
          <label htmlFor="time-search" style={{ paddingRight: "50px" }}>
            Nhập Uid phòng cần kiểm tra
          </label>
          <input
            id="time-search-start"
            type="number"
            onChange={(e) => setUid(e.target.value)}
            placeholder="Nhập uid phòng"
          />
          <br />
          <button className="btn" onClick={getLiveRoom}>
            Tìm Kiếm
          </button>
        </div>
        {
          loading === null ? (
            <h1>Lựa chọn Uid phòng để tra cứu data</h1>
          ) : loading === true ? (
            <h1>Đang tải dữ liệu...</h1>
          ) : (
            <table
              className="table table-bordered table-hover"
              style={{ marginTop: "20px" }}
            >
              <tbody>
                {liveRoom ? (
                  <>
                    <tr>
                      <th>Id</th>
                      <td>{liveRoom.id}</td>
                    </tr>
                    <tr>
                      <th>Uid</th>
                      <td>{liveRoom.uid}</td>
                    </tr>
                    <tr>
                      <th>Kind</th>
                      <td>{liveRoom.kind}</td>
                    </tr>
                    <tr>
                      <th>Who Can Join</th>
                      <td>{liveRoom.whoCanJoin}</td>
                    </tr>
                    <tr>
                      <th>Who Can Sing</th>
                      <td>{liveRoom.whoCanSing}</td>
                    </tr>
                    <tr>
                      <th>Background Type</th>
                      <td>{liveRoom.backgroundType}</td>
                    </tr>
                    <tr>
                      <th>Owner FbId</th>
                      <td>{liveRoom.ownerFbId}</td>
                    </tr>
                    {liveRoom.kind && liveRoom.kind !== "TALK" && (
                      <tr>
                        <th>Tool</th>
                        <td>
                          <button onClick={() => setTalkRoom()}>Đổi room Type to TALK</button>
                        </td>
                      </tr>
                    )}
                  </>
                ) : (
                  <tr>
                    <td colSpan={2}>Không có dữ liệu</td>
                  </tr>
                )}
              </tbody>
            </table>
          )
        }
      </div>
    </>
  );
  
}

export default LiveRoomInfo;
