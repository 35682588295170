import React, { useState, useEffect } from "react";
import axios from "axios";

function convertTimestampToVietnamTime(timestamp) {
    const date = new Date(timestamp);
    const options = {
        timeZone: 'Asia/Ho_Chi_Minh',
        hour: '2-digit',
        minute: '2-digit',
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    };

    return date.toLocaleString('vi-VN', options);
}

function SmartLookManager() {
    const [typeUserValue, setTypeUserValue] = useState('');
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [cursorVipUser, setCursorVipUser] = useState('');

    const getListUsers = async (type) => {
        switch (type) {
            case 'new-user':
                const today = new Date().toISOString().split('T')[0];
                const sevenDaysAgo = new Date();
                sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
                const sevenDaysAgoStr = sevenDaysAgo.toISOString().split('T')[0];
                const res_new_user = await axios.post(global.config.apiDomain + '/rest/new-device-and-user/new-user', { selectedDate: sevenDaysAgoStr, selectedEndDate: today }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                setLoading(false);
                setUsers(res_new_user.data.models || [])
                break;

            case 'vip-user':
                if (cursorVipUser == '') {
                    setUsers([]);
                }
                const res_vip_user = await axios.post(global.config.apiDomain + '/rest/TopVipUser/all-vip-user', { cursor: cursorVipUser }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                setLoading(false);
                setUsers((prevUsers) => [...prevUsers, ...res_vip_user.data.models]);
                setCursorVipUser(res_vip_user.data.cursor);
                break;

            case 'random-user':
                const res_random_user = await axios.get(global.config.apiDomain + '/rest/random-user/random-smartLook', {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                setLoading(false);
                setUsers((prevUsers) => [...prevUsers, ...res_random_user.data.models]);
                break;

            default:
                console.log("Invalid type specified.");
                break;
        }
    }

    const randomUserSmartLook = async () => {
        setLoading(true);
        const res_random_user = await axios.post(global.config.apiDomain + '/rest/random-user/random-smartLook', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        setLoading(false);
        setUsers((prevUsers) => [...prevUsers, ...res_random_user.data.models]);
    }

    const handleTypeUserChange = (event) => {
        const { value } = event.target;
        setUsers([]);
        setLoading(true);
        setCursorVipUser('');
        setTypeUserValue(value);
    }

    const findByTypeUser = () => {
        getListUsers(typeUserValue);
    }

    useEffect(() => {

    }, []);

    return (
        <>
            <div
                className="container"
                style={{
                    marginTop: "100px",
                    marginBottom: "100px",
                }}
            >
                <h1 className="text-center">SmartLook Manager</h1>
                <div className="filter-container mb-3">
                    <label htmlFor="time-search" style={{ paddingRight: "50px" }}>Tệp user cần tìm kiếm</label>
                    <select name="typeUserValue" onChange={handleTypeUserChange}>
                        <option value="">Chọn user...</option>
                        <option value="new-user">NEW USER</option>
                        <option value="vip-user">VIP USER</option>
                        <option value="random-user">RANDOM USER</option>
                    </select>
                    <br />
                    <button className="btn btn-primary" onClick={findByTypeUser}>
                        Tìm Kiếm
                    </button>
                    {typeUserValue == 'random-user' ? (
                        <button className="btn btn-success" style={{ marginLeft: "24px" }} onClick={randomUserSmartLook}>
                            Random User
                        </button>
                    ) : ''}
                </div>
                {
                    loading === true ? (
                        <h1 className='text-center'>Vui lòng chọn tệp user...</h1>
                    ) : (
                        <div>
                            <table
                                className="table table-bordered table-hover"
                                style={{ marginTop: "20px" }}
                            >
                                <thead className="table-primary">
                                    <tr>
                                        <th className="align-middle" scope="col">No.</th>
                                        <th className="align-middle" scope="col">AddTime</th>
                                        <th className="align-middle" scope="col">DeviceId</th>
                                        <th className="align-middle" scope="col">Platform</th>
                                        <th className="align-middle" scope="col">Uid</th>
                                        <th className="align-middle" scope="col">Name account</th>
                                        <th className="align-middle" scope="col">VipLevel</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {users.length > 0 ? (
                                        users.map((user, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{new Date(user.addTime).toLocaleString()}</td>
                                                <td>{user.deviceId}</td>
                                                <td>{user.platform}</td>
                                                <td>{user.uid}</td>
                                                <td>{user.name}</td>
                                                <td>{user.vipLevel}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={7}>Không có dữ liệu</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            {cursorVipUser != 'END' && typeUserValue == 'vip-user' ? (
                                <button className="btn btn-primary" onClick={() => getListUsers(typeUserValue)}>
                                    Tải thêm
                                </button>
                            ) : ''}
                        </div>
                    )
                }
            </div>
        </>
    );
}

export default SmartLookManager;
