import React, { Component } from 'react';
import axios from 'axios';
import lodash from 'lodash';
import ModalUpdateDailyTask from './components/ModalUpdateDailyTask';
import { Parser, Player } from 'svga';
import { Link } from "react-router-dom";

import '../../assets/css/giftmanager.css';
import $ from 'jquery';
import { toast } from 'react-toastify';

export default class DailyTaskPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dailyTasks: [],
            dailyTasksTemp: [],
            originalDailyTasks: [],
            selectGroup: '',
            selectType: '',
            selectRank: '',
            nameSearch: '',
            register: true,
            dailyTask: null
        }

        this.getDailyTasks = this.getDailyTasks.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.reset = this.reset.bind(this);
        this.newTask = this.newTask.bind(this);
        this.updateList = this.updateList.bind(this);
    }

    handleInputChange(event) {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    }

    handleSearch() {
        const {selectGroup, selectType, selectRank, originalDailyTasks, nameSearch} = this.state;
    
        const filteredDailyTasks = originalDailyTasks.filter(tasks => {
            const selectGroupMatch = !selectGroup || (tasks.group && tasks.group.toLowerCase().includes(selectGroup.toLowerCase()));
            const selectTypeMatch = !selectType || (tasks.type && tasks.type.toLowerCase().includes(selectType.toLowerCase()));
            const selectRankMatch = !selectRank || (tasks.rank && tasks.rank.toLowerCase().includes(selectRank.toLowerCase()));
            const nameMatch = !nameSearch || (tasks.name && tasks.name.toLowerCase().includes(nameSearch.toLowerCase()));
            return selectGroupMatch && selectTypeMatch && selectRankMatch && nameMatch;
        });
        this.setState({
            dailyTasks: filteredDailyTasks
        });
    }

    componentDidMount() {
        this.getDailyTasks();

        $("#modalUpdateDailyTask").on("hidden.bs.modal", function () {
            this.setState({
                dailyTask: null
            });
        }.bind(this));
    }

    getDailyTasks() {
        this.setState({
            loading: true
        });

        axios.get(global.config.apiDomain + '/rest/daily-task/get-list-daily-task')
            .then(res => {
                const dailyTasks = res.data.data.tasks;
                this.setState({
                    dailyTasks: dailyTasks,
                    dailyTasksTemp: dailyTasks,
                    originalDailyTasks: dailyTasks,
                    loading: false
                });
            })
            .catch(error => {
                console.error('Lỗi khi lấy danh sách nhiệm vụ:', error);
                this.setState({ loading: false });
            });
    }

    newTask() {
        this.setState({
            dailyTask:  {
                name : "",
                group : "",
                image : "",
                requestNumber : "",
                bonus : "",
                type : "",
                rank : "",
                screenInfo: "",
                minLevel: 0
            },
            register: true
        })
    }

    reset() {
        this.setState({
            nameSearch: "",
            show: "",
            type: "",
            tag: "",
            dailyTasks: this.state.dailyTasksTemp
        });
    }
   
    updateList(newdailyTask) {
        let dailyTasks = this.state.dailyTasks;

        let index = dailyTasks.findIndex(dailyTask => dailyTask.id === newdailyTask.id);
        if (index !== -1) {
            dailyTasks[index] = newdailyTask;
        } else {
            dailyTasks.unshift(newdailyTask);
        }

        this.setState({
            dailyTasks: dailyTasks,
        })
    }

    formatTime(seconds) {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
    
        return `${hours}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    }

    render() {
        const { dailyTasks, selectGroup, selectType, selectRank, nameSearch, register } = this.state;

        return (
            <>
                <ModalUpdateDailyTask resetList={this.updateList} dailyTask={this.state.dailyTask} register={register}></ModalUpdateDailyTask>
                <div id="content">
                    <div className="container-fluid">
                        <h1 className="text-center">Quản lý nhiệm vụ hằng ngày</h1>
                        <div>
                            <button
                                className="btn btn-red"
                                onClick={this.newTask}
                                data-toggle="modal"
                                data-target="#modalUpdateDailyTask"
                            >Thêm mới nhiệm vụ</button>
                        </div>
                        <div className="input-group mb-3">
                            <input
                                name="nameSearch"
                                value={nameSearch}
                                onChange={this.handleInputChange}
                                onKeyPress={(event) => {
                                    if (event.key === "Enter") {
                                        this.handleSearch()
                                    }
                                }}
                                type="text"
                                className="form-control col-md-2 col-sm-6 "
                                placeholder="Nhập tên nhiệm vụ"
                                aria-label="Recipient's username"
                                aria-describedby="button-addon2" />
                            <select
                                name="selectGroup"
                                value={selectGroup}
                                className="form-control col-md-1 col-3"
                                onChange={this.handleInputChange}>
                                <option value="">Id nhiệm vụ</option>
                                <option value="Tặng quà phòng kara">Tặng quà phòng kara</option>
                                <option value="Tặng quà bài thu">Tặng quà bài thu</option>
                                <option value="Tặng quà combo">Tặng quà combo</option>
                                <option value="Tặng lì xì">Tặng lì xì</option>
                                <option value="Tặng quà cặp đôi">Tặng quà cặp đôi</option>
                                <option value="Thu âm đơn ca">Thu âm đơn ca</option>
                                <option value="Thu âm song ca">Thu âm song ca</option>
                                <option value="Thu âm đơn ca có hình">Thu âm đơn ca có hình</option>
                                <option value="Thu âm song ca có hình">Thu âm song ca có hình</option>
                                <option value="Thích bài thu">Thích bài thu</option>
                                <option value="Bình luận bài thu">Bình luận bài thu</option>
                                <option value="Chia sẻ bài thu">Chia sẻ bài thu</option>
                                <option value="Nhặt lì xì">Nhặt lì xì</option>
                                <option value="Vào phòng kara">Vào phòng kara</option>
                                <option value="Vào phòng talk">Vào phòng talk</option>
                                <option value="Vào phòng game">Vào phòng game</option>
                                <option value="Chơi game">Chơi game</option>
                                <option value="Nhắn tin bạn bè">Nhắn tin bạn bè</option>
                                <option value="Hoàn thành hồ sơ">Hoàn thành hồ sơ</option>
                                <option value="Tham gia sự kiện">Tham gia sự kiện</option>
                                <option value="Theo dõi bạn bè">Theo dõi bạn bè</option>
                                <option value="Xử lý AI">Xử lý AI</option>
                                <option value="Nghe bài thu">Nghe bài thu</option>
                                <option value="Được bạn bè tặng quà">Được bạn bè tặng quà</option>
                                <option value="Mua VIP">Mua VIP</option>
                                <option value="Nạp Icoin">Nạp Icoin</option>
                                <option value="Ghép cặp đôi">Ghép cặp đôi</option>
                                <option value="Song ca cùng thần tượng">Song ca cùng thần tượng</option>
                                <option value="Lên bài">Lên bài</option>
                                <option value="Lên ghế">Lên ghế</option>
                                <option value="Tạo beat chờ song ca">Tạo beat chờ song ca</option>
                                <option value="Ghé thăm bạn bè">Ghé thăm bạn bè</option>
                                <option value="Thu âm có sticker">Thu âm có sticker</option>
                            </select>
                            <select
                                name="selectType"
                                value={selectType}
                                className="form-control col-md-1 col-3"
                                onChange={this.handleInputChange}>
                                <option value="">Type</option>
                                <option value="NEW_USER">NEW USER</option>
                                <option value="NORMAL">NORMAL</option>
                            </select>
                            <select
                                name="selectRank"
                                value={selectRank}
                                className="form-control col-md-1 col-3"
                                onChange={this.handleInputChange}>
                                <option value="">Rank</option>
                                <option value="EASY">EASY</option>
                                <option value="NORMAL">NORMAL</option>
                                <option value="HARD">HARD</option>
                            </select>

                            <div className="input-group-append">
                                <button
                                    className="btn btn-outline-success"
                                    type="button"
                                    id="button-addon2"
                                    onClick={this.handleSearch} style={{height: '38px'}}>
                                    <i className="fa fa-search"></i>
                                </button>
                                <button
                                    className="btn btn-outline-danger"
                                    type="button"
                                    id="button-addon2"
                                    onClick={this.reset} style={{height: '38px'}}>
                                    <i className="fas fa-sync"></i>
                                </button>
                            </div>
                        </div>
                        <table className="table table-hover">
                            <thead>
                                <tr className="align-middle">
                                    <th className="align-middle" scope="col">Id</th>
                                    <th className="align-middle" scope="col">Id nhiệm vụ</th>
                                    <th className="align-middle" scope="col">Icon</th>
                                    <th className="align-middle" scope="col">Tên nhiệm vụ</th>
                                    <th className="align-middle" scope="col">Min level</th>
                                    <th className="align-middle" scope="col">Số lượng</th>
                                    <th className="align-middle" scope="col">Icoin được tặng</th>
                                    <th className="align-middle" scope="col">Thời gian hoàn thành</th>
                                    <th className="align-middle" scope="col">Type ( loại tân thủ/ nhiệm vụ user)</th>
                                    <th className="align-middle" scope="col">Độ khó</th>
                                    <th className="align-middle" scope="col">Id màn hình đi tới</th>
                                    <th className="align-middle" scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dailyTasks.map((dailyTask, index) => {
                                    const rowClass = dailyTask.type === 'NEW_USER' ? 'new-user-row' : '';

                                    return (
                                        <tr key={dailyTask.id} className={rowClass}>
                                            <td>{dailyTask.id}</td>
                                            <td>{dailyTask.group}</td>
                                            <td> <img className="gift-image" src={dailyTask.image}></img>  </td>
                                            <td>{dailyTask.name}</td>
                                            <td>{dailyTask.minLevel}</td>
                                            <td> {dailyTask.requestNumber} </td>
                                            <td> {dailyTask.bonus} </td>
                                            <td>
                                                {dailyTask.averageTime !== 0 ? this.formatTime(dailyTask.averageTime) : '-'}
                                            </td>
                                            <td> {dailyTask.type} </td>
                                            <td> {dailyTask.rank} </td>
                                            <td> {dailyTask.screenId} </td>
                                            <td>
                                                <button
                                                    onClick={() => {
                                                        this.setState({
                                                            dailyTask: dailyTask,
                                                            register: false
                                                        })
                                                    }}
                                                    className="btn btn-red"
                                                    data-toggle="modal"
                                                    data-target="#modalUpdateDailyTask"
                                                >Sửa</button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        );
    }
}
