import React, { Component } from 'react';
import axios from 'axios';

export default class CommentListPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            commentlist: [],
            needreply:[],
            recordingSelected: null
        }
        this.getBOTCommentList = this.getBOTCommentList.bind(this);
    }

    componentDidMount() {
        // Sử dụng param từ props để thực hiện các hành động cần thiết, ví dụ như gọi API
        const { param } = this.props;
        this.getBOTCommentList(param);
    }

    componentDidUpdate(prevProps) {
        // Kiểm tra nếu param đã thay đổi
        if (prevProps.param !== this.props.param) {
            console.log('Param has changed:', this.props.param);
            // Gọi lại API với param mới
            this.getBOTCommentList(this.props.param);
        }
    }

    getBOTCommentList(param) {
        var json = {
            userId: param
        }
        axios.post(global.config.apiDomain + '/rest/bot-manager/get-bot-need-reply-comment-recording-by-id', JSON.stringify(json), {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                this.setState({
                    commentlist: res.data.recording
                });
                console.log(res.data.recording);
            })
    }

    handleCommentClick(param) {
        // Gọi hàm từ props và truyền param nếu cần
        this.props.onCommentClick(param);
        this.setState({
            recordingSelected: param
        })
    }

    render() {
        const { param } = this.props;
        const { commentlist, recordingSelected } = this.state;
        return (
            <>
                <div id="commentlist">
                    <div className="col-md-2">
                        {commentlist && commentlist.map((record, index) => (
                            <tr className={`flex-container ${record.id == recordingSelected ? 'active-bot text-white' : ''}`} key={index} onClick={() => this.handleCommentClick(record.id)}>
                            <div className="flex-item-left">
                                <div className="uid-row" style={{fontWeight: "bold"}}>{record.recordingName}</div>
                                <div className="name-row" style={{fontWeight: "bold"}}>{record.ownerName}</div>
                            </div>
                            <td className="flex-item-right avatar-div">
                                <img src={record.image} alt="avatar" className="avatar" />
                                {record.needReply && <i className="fa-solid fa-circle-exclamation"></i>}
                            </td>
                        </tr>
                        ))}
                    </div>
                </div>
            </>
        );
    }
}
