import React, { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

export default class ModalShowGiftLog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userId: null,
            giftId: null,
            quantity: null,
            giftLogs: [], 
            loading: false
        }
        this.getGiftsLog = this.getGiftsLog.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            userId: nextProps.userId,
            giftId: nextProps.giftId,
            quantity: nextProps.quantity,
            giftLogs: []
        });
        this.getGiftsLog(nextProps.userId, nextProps.giftId, nextProps.quantity);
    }
    componentDidMount(){
        this.getGiftsLog(this.props.userId,this.props.giftId,this.props.quantity)
    }
    getGiftsLog(userId, giftId, quantity) {
        this.setState({ loading: true });
        axios.post(global.config.apiDomain + '/rest/gifts/getGiftsLog', { userId, giftId, quantity}, {
            headers: { 'Content-Type': 'application/json' }
        }).then(res => {
            console.log("Gift logs response: ", res.data);
            this.setState({
                giftLogs: res.data.list,
                loading: false
            });
        }).catch(err => {
            console.error("Error fetching gift logs:", err);
            this.setState({ loading: false });
        });
    }

    render() {
        const { giftLogs, loading } = this.state;
    
        return (
            <div>
                <div className="modal fade" id="modalShowGiftLog" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Chi tiết quà</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {loading ? (
                                    <div className="text-center">
                                        <div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                ) : giftLogs.length > 0 ? (
                                    giftLogs.map((log, index) => (
                                        <div key={index} className="card mb-3">
                                            <div className="card-body">
                                                <h6 className="card-title">{log.giftId} #{index + 1}</h6>
                                                <p className="card-text">
                                                    <strong>Thời gian nhận:</strong> {new Date(log.addTime).toLocaleString()}
                                                </p>
                                                {log.senderUid && log.senderUid !== 0 ? (
                                                    <p className="card-text">
                                                        <strong>UID người tặng:</strong> {log.senderUid}
                                                    </p>
                                                ) : null}
                                                {log.recordingId && log.recordingId !== 0 ? (
                                                    <p className="card-text">
                                                        <strong>Recording ID:</strong> {log.recordingId}
                                                    </p>
                                                ) : null}
                                                {log.liveRoomId && log.liveRoomId !== 0 ? (
                                                    <p className="card-text">
                                                        <strong>Live Room ID:</strong> {log.liveRoomId}
                                                    </p>
                                                ) : null}
                                                <p className="card-text">
                                                    <strong>Số lượng nhận:</strong> {log.noItem}
                                                </p>
                                                {log.luckyGiftName && log.luckyGiftName.trim() !== "" ? (
                                                    <p className="card-text">
                                                        <strong>Tên lì xì:</strong> {log.luckyGiftName}
                                                    </p>
                                                ): null}
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <p>Không có dữ liệu quà tặng</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    
    
    
}
