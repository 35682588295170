import React, { Component } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import ModalCreateUpdateCopyright from './ModalCreateUpdateCopyright'
import { toast } from 'react-toastify';

export default class CopyrightPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            CopyrightModels: [],
            relatedWordVietnameses :[],
            blockWordVietnamese:'',
            loading: false,
            id : 0,
        }
        this.getCopyright = this.getCopyright.bind(this);
    }

    getCopyright() {
        console.log("1111");
            axios.post(global.config.apiDomain + "/rest/copyright/get")
                .then(res => {
                    if (res.data.status == "OK") {
                        this.setState({
                            CopyrightModels: res.data.CopyrightModels
    
                        })
                        console.log(res.data)
                    }
                })
        }
    addNewCopyright=()=>{
        this.setState({
            relatedWordVietnameses :[],
            blockWordVietnamese : '',
            id:0
        })
    }
    updateCopyright =(e)=>{
        let index = e.target.name
        let CopyrightModels =  [...this.state.CopyrightModels]
        let relatedWordVietnameses = CopyrightModels[index].relatedWordVietnamese
        let blockWordVietnamese = CopyrightModels[index].blockWordVietnamese
        let id = CopyrightModels[index].Id
        this.setState({
            relatedWordVietnameses :relatedWordVietnameses,
            blockWordVietnamese : blockWordVietnamese,
            id:id
        })
    }
    deleteCopyright =(e)=>{
        let index = e.target.name
        let value = e.target.value
        console.log(value);
        
        let CopyrightModels =  [...this.state.CopyrightModels]
        // let relatedWordVietnameses = CopyrightModels[index].relatedWordVietnamese
        // let blockWordVietnamese = CopyrightModels[index].blockWordVietnamese
        // let id = CopyrightModels[index].Id
        axios.post(global.config.apiDomain + "/rest/copyright/delete",{
            Id:value
        })
                .then(res => {
                    if (res.data.status == "OK") {
                        CopyrightModels.splice(index,1)
                        this.setState({
                            CopyrightModels: CopyrightModels
    
                        })
                        toast.success("Xoá thành công")
                        console.log(res.data)
                    }
                })

        // this.setState({
        //     relatedWordVietnameses :relatedWordVietnameses,
        //     blockWordVietnamese : blockWordVietnamese,
        //     id:id
        // })
    }

    // componentDidUpdate() {
    //     const { botlist, bot } = this.state;
    //     botlist.map((bot, index) => {
    //         // console.log(screen)
    //     })

    // }

    componentDidMount() {
        this.getCopyright();
    }

    render() {
        const { CopyrightModels, blockWordVietnamese ,relatedWordVietnameses, id } = this.state;
        return (
            <>
            <ModalCreateUpdateCopyright
                    relatedWordVietnameses={relatedWordVietnameses}
                    blockWordVietnamese ={blockWordVietnamese}
                    id={id}
                    resetList={this.getCopyright}
                >
                </ModalCreateUpdateCopyright>
                <div id="content">
                    <div className="container-fluid">
                        <h1 className="text-center">Quản lí Từ Khoá bản quyền
                        </h1>
                        <button
                            className="btn btn-red mb-1"
                            data-toggle="modal"
                            data-target="#modalUpdateVipPrivilege"
                            onClick={this.addNewCopyright}
                        >+ Thêm Từ Khoá
                        </button>
                        <table className="table table-hover">
                            <thead>
                                <tr className="text-center">
                                    <th className="align-middle" scope="col">STT</th>
                                    <th className="align-middle" scope="col">Từ khoá bản quyền</th>
                                    <th className="align-middle" scope="col">Từ khoá quyền Tiếng Việt</th>
                                    <th className="align-middle" scope="col">Từ khoá liên quan </th>
                                    <th className="align-middle" scope="col">Từ khoá liên quan Tiếng Việt</th>
                                    <th className="align-middle" scope="col">Hành Động</th>


                                </tr>
                            </thead>
                            <tbody>
                                {CopyrightModels && CopyrightModels.map((item, index) => {
                                    return (
                                        <tr>
                                            <td className="align-middle text-center" scope="col">{index + 1}</td>
                                            <td className="align-middle text-center" scope="col">{item.blockWord}</td>
                                            <td className="align-middle text-center" scope="col">{item.blockWordVietnamese}</td>
                                            <td className="align-middle text-center" scope="col">{item.relatedWords.join(" - ")}</td>
                                            <td className="align-middle text-center" scope="col">{item.relatedWordVietnamese.join(" - ")}</td>
                                            <td className='align-middle text-center'>
                                                    <button
                                                        className="btn btn-success mr-2"
                                                        onClick={this.updateCopyright}
                                                        data-toggle="modal"
                                                        name={index}
                                                        data-target="#modalUpdateVipPrivilege"

                                                    >
                                                        Sửa
                                                    </button>
                                                    <button
                                                        className="btn btn-danger"
                                                        onClick={this.deleteCopyright}
                                                        name={index}
                                                        value={item.Id}
                                                    >
                                                        Xoá
                                                    </button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        );
    }
}

