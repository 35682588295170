import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';
import ModalUpdateSurvey from './components/ModalUpdateSurvey';
import ModalDeleteSurvey from './components/ModalDeleteSurvey';

import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import ModalSurveyStatistics from './components/ModalSurveyStatistics';
import ModalCompare from './components/ModalCompare';


export default class SurveyNPSPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            surveys: [],
            surveyModal: null,
        }
        this.getAllSurvey = this.getAllSurvey.bind(this);
        this.createOrUpdateSurvey = this.createOrUpdateSurvey.bind(this);
        this.deleteSurveyOrGetSurveyStatistics = this.deleteSurveyOrGetSurveyStatistics.bind(this);
    }

    componentWillMount() {
        this.getAllSurvey();
    }

    getAllSurvey() {
        let url = global.config.apiDomain + "/rest/survey/getAllSurveyNPS"
        axios.post(url)
            .then(res => {
                this.setState({
                    surveys: res.data.surveys
                })
            })
    }

    convertDateToString(surveyModal) {
        surveyModal.showTime = new Date(surveyModal.showTime).toLocaleString("sv", {timeZone: "Asia/Ho_Chi_Minh"}).replace(" ", "T");
        surveyModal.endTime = new Date(surveyModal.endTime).toLocaleString("sv", {timeZone: "Asia/Ho_Chi_Minh"}).replace(" ", "T");
        return surveyModal;
    }

    createOrUpdateSurvey(event) {
        let index = event.target.name;
        let surveyModal = null;
        if (index === "-1") {
            surveyModal = {
                id: null,
                answers: [],
                type: null,
                screenId: 0,
                levelRequire: 0,
                vipRequire: 0,
                showTime: new Date().toLocaleString("sv", {timeZone: "Asia/Ho_Chi_Minh"}).replace(" ", "T"),
                endTime: new Date().toLocaleString("sv", {timeZone: "Asia/Ho_Chi_Minh"}).replace(" ", "T"),
                appearNextScreen: 'false'
            }
        } else {
            surveyModal = this.state.surveys[index];
            surveyModal = this.convertDateToString(surveyModal);
            if (surveyModal.appearNextScreen) {
                surveyModal.appearNextScreen = 'true';
            } else {
                surveyModal.appearNextScreen = 'false';
            }
        }

        this.setState({
            surveyModal: surveyModal
        })

    }

    deleteSurveyOrGetSurveyStatistics(event) {
        let index = event.target.name;
        let surveyModal = this.state.surveys[index];

        this.setState({
            surveyModal: surveyModal
        })
    }

    render() {
        const { surveys, surveyModal } = this.state;
        return (
            <>
                <ModalUpdateSurvey 
                    surveyModal={surveyModal}
                    resetList={this.getAllSurvey}
                ></ModalUpdateSurvey>
                <ModalDeleteSurvey 
                    surveyModal={surveyModal}
                    resetList={this.getAllSurvey}
                ></ModalDeleteSurvey>
                <ModalSurveyStatistics surveyModal={surveyModal}></ModalSurveyStatistics>
                <div id="content">
                    <div className="container-fluid">
                        <h1 className="text-center">Khảo sát NPS</h1>
                        <button className='btn btn-success mb-2'
                            data-toggle="modal"
                            data-target="#modalUpdateSurvey"
                            name="-1"
                            onClick={this.createOrUpdateSurvey}
                        >Thêm Mới</button>
                        <button
                            style={{ marginLeft: '10px' }}
                            className='btn btn-success mb-2'
                            data-toggle="modal"
                            data-target="#modalCompare"
                        >
                            So sánh
                        </button>
                        <ModalCompare />
                        <table className="table table-hover">
                            <thead>
                                <tr className="text-center">
                                    <th className="align-middle" scope="col">ID</th>
                                    <th className="align-middle" scope="col">Câu hỏi</th>
                                    <th className="align-middle" scope="col">Màn hình khảo sát</th>
                                    <th className="align-middle" scope="col">Xuất hiện</th>
                                    <th className="align-middle" scope="col">Giới hạn lượt</th>
                                    <th className="align-middle" scope="col">Thời gian hiển thị</th>
                                    <th className="align-middle" scope="col">Thời gian kết thúc</th>
                                    <th className="align-middle" scope="col">Kết quả</th>
                                    <th className="align-middle" scope="col">Chỉnh sửa</th>
                                </tr>
                            </thead>
                            <tbody>
                                {surveys && surveys.map((item, index) => {
                                    return (
                                        <>
                                            <tr>
                                                <td className="align-middle text-center" scope="col">{item.id}</td>
                                                <td className="align-middle text-center" scope="col">{item.question}</td>
                                                <td className="align-middle text-center" scope="col">{item.screenAppear.screenName}</td>
                                                <td className="align-middle text-center" scope="col">{item.appearNextScreen? "Màn hình TIẾP THEO" : "Màn hình HIỆN TẠI"}</td>
                                                <td className="align-middle text-center" scope="col">{item.limitAnswers}</td>
                                                <td className="align-middle text-center" scope="col">{new Date(item.showTime).toLocaleString()}</td>
                                                <td className="align-middle text-center" scope="col">{new Date(item.endTime).toLocaleString()}</td>
                                                <td className="align-middle text-center" scope="col">
                                                    <button className='btn btn-info' style={{width: '146px'}}
                                                        data-toggle="modal"
                                                        data-target="#modalSurveyStatistics"
                                                        name={index}
                                                        onClick={this.deleteSurveyOrGetSurveyStatistics}
                                                    >Kết quả khảo sát</button>
                                                </td>
                                                <td className="align-middle text-center" scope="col">
                                                    {
                                                        item.deleted ? (
                                                            <button style={{ width: '138px', backgroundColor: 'red' }}
                                                                className="btn btn-info"
                                                                onClick={this.deleteSurveyOrGetSurveyStatistics}
                                                                name={index}
                                                                data-toggle="modal"
                                                                data-target="#modalDeleteSurvey"
                                                            >
                                                                Mở lại
                                                            </button>
                                                        ) : (
                                                            <>
                                                                <div className="d-flex">
                                                                    <button
                                                                        className="btn btn-success"
                                                                        onClick={this.createOrUpdateSurvey}
                                                                        name={index}
                                                                        data-toggle="modal"
                                                                        data-target="#modalUpdateSurvey"
                                                                    >
                                                                        Sửa
                                                                    </button>
                                                                    &emsp;
                                                                    <button
                                                                        className="btn btn-warning"
                                                                        onClick={this.deleteSurveyOrGetSurveyStatistics}
                                                                        name={index}
                                                                        data-toggle="modal"
                                                                        data-target="#modalDeleteSurvey"
                                                                    >
                                                                        Ẩn
                                                                    </button>
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                </td>
                                            </tr>
                                        </>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        );
    }
}