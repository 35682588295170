
module.exports = global.config = {
    // "apiDomain": "http://localhost:8080",
    // "apiDomain": window.location.origin,
    "apiDomain": window.location.hostname == "localhost" ? "https://ikara-development.appspot.com" : window.location.origin,
    // "apiDomain": window.location.hostname == "localhost" ? "https://www.ikara.co/" : window.location.origin,
    "data3":"http://data3.ikara.co:8082",
    "apiAi":"http://ai.ikara.co:1339/video_recommender",
    "smartLook": {
        "getAllUsersSmartLook":"https://api.eu.smartlook.cloud/api/v2/visitors/search?limit=100",
        "getSessionSmartLook":"https://api.eu.smartlook.cloud/api/v1/sessions/search?limit=1"
    },
    "fromServer": window.location.hostname.includes("ikara-development") ? "DEV" : "PROD",
    "appName": window.location.hostname.split(".")[1],
    "yokara_version": "YO-WEB-1.0.1",
    "ikara_version": "IK-WEB-1.0.2",
    "okara_lo_version": "LO-WEB-1.0.0",
    "okara_tl_version": "TL-WEB-1.0.2",
    "okara_mz_version": "MZ-WEB-1.0.0",
    "okara_my_version": "MY-WEB-1.0.1",
    "okara_ht_version": "MY-WEB-1.0.0"
};
