import React, { Component } from 'react';
import axios from 'axios';
import ModalAddPartner from './components/ModalAddPartner';
import ModalEditPartnerRoom from './components/ModalEditPartnerRoom';
import { toast } from 'react-toastify';

export default class PartnerManagerPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            partners: [],
            partner: null,
            uid: null,
            roomUid: null,
            loading: false
        }

        this.getAllPartner = this.getAllPartner.bind(this);
        this.search = this.search.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentWillMount() {
        this.getAllPartner();
    }

    getAllPartner() {
        this.setState({ loading: true });
        axios.post(global.config.apiDomain + '/rest/partner/get-partner', JSON.stringify(''), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            this.setState({
                partners: res.data,
                loading: false
            })
        }).catch(error => {
            console.error('Error during search:', error);
            this.setState({ loading: false });  // Stop loading on error
        });;
    }


    search() {
        this.setState({ loading: true });
        let { uid } = this.state;
        axios.post(global.config.apiDomain + '/rest/partner/get-partner?uid=' + uid, JSON.stringify(''), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            this.setState({
                partners: res.data,
                loading: false,
            })
        }).catch(error => {
            console.error('Error during search:', error);
            this.setState({ loading: false });  // Stop loading on error
        });;

    }

    handleChange(event) {
        let target = event.target;
        let name = target.name;
        let value = target.value;

        this.setState({
            [name]: value
        });
    }

    handleDeletePartner(deletePartner) {
        const json = {
            uid: deletePartner.uid,
            role: 'USER'
        }
        axios
            .post(
                global.config.apiDomain + "/rest/privilegeAdmin/create",
                json
            )
            .then((res) => {
                if (res.data.status == "FAILED") {
                    toast.warning(res.data.message);
                } else {
                    toast.success(res.data.message);

                    let partners = this.state.partners;

                    let index = partners.findIndex(partner => partner.id === deletePartner.id);
                    if (index !== -1) {
                        partners.splice(index, 1);
                    }

                    this.setState({
                        partners: partners,
                    })
                }
            })
            .catch((err) => {
                toast.warning("Xóa CTV thất bại ");
            });
    }

    render() {
        const { partner, loading } = this.state;
        return (
            <>
                <ModalAddPartner resetList={this.getAllPartner}></ModalAddPartner>
                <ModalEditPartnerRoom partner={partner} resetList={this.getAllPartner}></ModalEditPartnerRoom>
                <div id="content">
                    <div className="container-fluid">
                        <h1 className="text-center">Quản lí Cộng Tác Viên</h1>
                        <div className='input-group mb-3'>
                            <input
                                name="uid"
                                value={this.state.uid}
                                className="form-control col-md-1 col-3"
                                placeholder='Nhập uid CTV'
                                onChange={this.handleChange}
                            />
                            <div className="input-group-append ml-1">
                                <a
                                    style={{
                                        height: "20px!important",
                                        width: "38px",
                                        padding: "7px 0 0 11px",
                                        background: "#25A0FD",

                                    }}
                                    onClick={this.search}
                                >
                                    <i className="fa fa-search"></i>
                                </a>
                            </div>

                            <div className="input-group-append ml-1">
                                <a
                                    style={{
                                        height: "20px!important",
                                        width: "105px",
                                        padding: "7px 0 0 11px",
                                        background: "#00b55a",

                                    }}
                                    data-toggle="modal"
                                    data-target="#modalAddPartner"
                                >
                                    <i className="fa fa-plus"></i> Thêm CTV
                                </a>
                            </div>
                        </div>
                        {loading ? (
                            <div className="text-center">
                                <i className="fa fa-spinner fa-spin fa-2x"></i>
                                <p>Đang tải...</p>
                            </div>
                        ) : (
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col">STT</th>
                                        <th scope="col">Tên</th>
                                        <th scope="col">UID</th>
                                        <th scope="col">Phòng live đang quản lý</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.partners.map((partner, index) => {
                                        return (
                                            <tr key={partner.id}>
                                                <th scope="row">{index + 1}</th>
                                                <td> {partner.name} </td>
                                                <td> {partner.uid} </td>
                                                <td>
                                                    <ul>
                                                        {partner.rooms.map((room, roomIndex) => (
                                                            <li key={roomIndex}>
                                                                {room.uid} - {room.name}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </td>
                                                <td>
                                                    <a
                                                        data-toggle="modal"
                                                        data-target="#modalEditPartnerRoom"
                                                        onClick={() => {
                                                            this.setState({
                                                                partner: partner
                                                            })
                                                        }}
                                                    >
                                                        <i className="fa fa-pencil-square-o"></i>
                                                    </a>
                                                    <a
                                                        style={{ marginLeft: '10px' }}
                                                        onClick={() => this.handleDeletePartner(partner)}
                                                    >
                                                        <i className="fa fa-trash"></i>
                                                    </a>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>
            </>
        );
    }
}